export default function SidebardTop(){
    return(
        <div className="offcanvas offcanvas-top" tabIndex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
            <div className="offcanvas-header">
                <h5 id="offcanvasTopLabel">Offcanvas top</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                ...
            </div>
        </div>          
    )
}
