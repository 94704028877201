import React, {useState, useRef, useEffect} from 'react';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox, ListItemText, ListSubheader } from '@mui/material';
import { color } from '@mui/system';



export default function FilterPicker({...props}){
    // Valeur par défaut    
    const defaultValue =  []
    const defaultCompare = "IN"
    const defaultPlaceholder =  "Sélectionner une option";

    // Valeur à afficher en fonction de l'existance ou non d'une précedente requête
    const filterValue = props.filter.value ? props.filter.value : defaultValue
    const filterCompare = props.filter.compare ? props.filter.compare : defaultCompare
    const filterPlaceholder = props.placeholder === "" ? false : props.placeholder ? props.placeholder : defaultPlaceholder    

    const select = useRef()
    const [placeholder] = useState(filterPlaceholder)
    const [value, setValue] = useState(filterValue)
    const [filter, setFilter] = useState({
        event:'',
        id : props.id,
        value: filterValue,
        compare: filterCompare,
    })

    const handleAdornment = (e,value)=>{
        setFilter({
            ...filter,
            event:e,
            compare : value,
        })
    }

    /**
     * handleChange
     * Description : Suppression de la valeur zéro du array correspondant 
     * au placeholder et modification du filtre
     * @param {*} e 
     * @param {*} value 
     */
    const handleChange = (e,value)=>{
        // Permet de supprimer le placeholder de la selection
        const i = e.target.value.indexOf("-")
        if(i >= 0) e.target.value.splice(i, 1)
        // Dans le cas des itemsGroup permet de ne pas selectionner les titres
         const k = e.target.value.indexOf(undefined)
         if(k >= 0) e.target.value.splice(k, 1)
        // Modification du filtre
        setFilter({
            ...filter,
            event:e,
            value: e.target.value,
        })
    }
    
    const itemSort = (list,key) =>{
        return list.reduce((r, a) => { r[a[key]] = [...r[a[key]] || [], a]; return r;}, {});
    }

    // Affiche la liste des items
    const getItem = (options)=>{
        return options.map(
            (option)=>(
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            )
        )
    }

    // ATTENTION NE FONCTIONNE PAS POUR L'INSTANT 
    // Affiche la liste des items avec la case à case à cocher
    const getItemChek = (options)=>{
        return options.map(
            (option)=>(
                <MenuItem key={option.value} value={option.value}>
                    <Checkbox checked={value.indexOf(option.label) > -1} />
                    <ListItemText primary={option.label} />        
                </MenuItem>
            )
        )
    }

    // Affiche les items sous forme groupés
    const getItemGroup = (options,filter)=>{
        const items = itemSort(options,filter)
        return Object.entries(items).map(
            (k,v)=> [
                <ListSubheader color="primary" key={k[0]}><strong>{k[0]}</strong></ListSubheader>,
                k[1].map((item,i) => <MenuItem value={item.value}>{item.label}</MenuItem>)
            ]
        )
    }

    // const groupBy = (list, keyGetter) => {
    //     const map = new Map();
    //     list.forEach((item) => {
    //         const key = keyGetter(item);
    //         const collection = map.get(key);
    //         if (!collection) {map.set(key, [item]);} 
    //         else {collection.push(item);}
    //     });
    //     return map;
    // }

    /**
     * useEffect 
     * Si la valeur du filtre est vide alors sélection de l'option zéro 
     * correspondant au placeholder. le cas contraire selection des valeurs
     */
    useEffect(()=>{
        //console.log("filter",filter.value.length > 0 ? filter.value : ["-"])
        setValue(filter.value.length > 0 ? filter.value : ["-"] ) 
        //setValue(filter.value !== "-" ? filter.value : ["-"] ) 
        props.onChange(filter.event,filter)
        //Permet l'affichage en couleur lorsque un élément est sélectionné         
        const className = select.current.querySelector(`#${props.id}`)
        if(filter.value.length > 0) className.classList.add("text-primary")
        else className.classList.remove("text-primary")
    },
    [filter])

    useEffect(()=>{
        if(props.reset) {
            setFilter({
                ...filter,
                value : defaultValue,
            })            
        }
    },
    [props.reset])

    return(
    <FormControl 
        fullWidth
        variant="standard" 
        sx={{ mb:1, mt:1 }} 
    >
        <InputLabel id={"label-"+props.id}>{props.label}</InputLabel>
        <Select
            ref={select}
            multiple
            id={props.id}
            value={value}
            labelId={"label-"+props.id}
            label={props.label}
            onChange={handleChange}
            size="small"
            startAdornment={<Adornment compare={filterCompare} onChange={handleAdornment} reset={props.reset} />}
            MenuProps={{PaperProps: {style:{maxHeight:250}}}}
            // renderValue={(selected) => <span style={{color:"#0d6efd"}} >{selected.join(', ')}</span>}
            //renderValue={(selected,toto) => {console.log(selected,props.options.find(({ value: v }) => v === value ));return <span style={{color:"#0d6efd"}} >{selected.join(', ')}</span>}}
        >
            {placeholder && <MenuItem disabled value="-" ><em>{placeholder}</em></MenuItem>}
            {props.options && (props.groupe ? getItemGroup(props.options, props.groupe) : getItem(props.options))}
            {/* {props.options && props.options.map((option)=>(<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}     */}
        </Select>    
    </FormControl>
    )
}


/**
 * Gestion des signes de comparaison des filtres 
 * @param {*} props 
 * @returns 
 */
const Adornment = function(props){
    const styles = {border:0,outline:0,padding:5}

    const defaultValue = props.defaultValue || "IN";
    const compareValue = props.compare ? props.compare : defaultValue
    const ref = useRef()

    const [value, setValue] = useState(compareValue)
    const [title, setTitle] = useState()

    const handleChange = (e)=>{
        setValue(e.target.value)
        const index = e.nativeEvent.target.selectedIndex
        setTitle(e.nativeEvent.target[index].title)
        props.onChange(e,e.target.value)
    }

    // Au chargement permet l'affichage du title lorsque l'on passe au dessus du signe
    useEffect(()=>{
        const index = ref.current.options.selectedIndex
        setTitle(ref.current.options[index].title)
    },[value])
    
    // Dans le cas ou l'utilisateur demande un reset du filtre 
    useEffect(()=>{
        if(props.reset) setValue(defaultValue)
    },[props.reset])
    

    return (
    <select ref={ref} style={styles} title={title} value={value} onChange={handleChange} > 
        <option value="IN" title="Egal à" >=</option>
        <option value="NOT IN" title="Différent de" >!=</option>
    </select>        
    )
}