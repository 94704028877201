import {useState,useContext, useEffect} from 'react';
import {PropTypes} from 'prop-types';

import Alert from 'react-bootstrap/Alert'

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faAt } from "@fortawesome/free-solid-svg-icons"

import { AuthContext } from '../app/AuthContext'
import api from "../../utils/api"
import logo from '../../assets/images/brand/app-logo.png'
import InputMail from '../../components/html/input/InputMail';

/******************************************************************************
 * LogIn
 * - Formulaire de connexion est affiché lorsqu'il n'existe pas d'utilisateur  
 * @param {onConnect, setCookie} param
 * - onConnect : Permet un setUser qui va créer un utilisateur pour l'application 
 * - setCookie : Permet la création du cookie permettant la reconnexion automatique 
 * @returns 
 *****************************************************************************/
export default function LogIn({setLogged, setCookie}){
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState({
        email: '',
        password: '',
        rememberme: false,
        showPassword: false,
    });

    const [authState, setAuthState] = useContext(AuthContext);  
  
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleCheck =  (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.checked });
    };
  
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
  
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
  
    /**************************************************************************
     * Lorsque le formulaire est soumis - 
     * - Envoi de la requête permettant l'authentification
     * - Dans le cas d'un retour positif le format de l'objet est composé
     *   {user:{},identity:{},exchange:{},adresse:{}}
     * - Ajout du token dans l'ensemble des requêtes (headers) 
     *   pour l'authentification dans les routes securisées
     * - Si l'utilisateur demande à etre reconnu création d'un cookie permettant
     *   la connexion automatique pendant 24 heures
     * - Si une erreur est détecté lors le l'authentification affichage d'un 
     *   message permettant de savoir si le probleme vient de l'email / password  
     * @param {event} e 
     *************************************************************************/
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setError(null)
        setLoading(true)
        await api.post('/auth',values)
           .then(r=>{
                if(r.status === 200 && !r.data.error){ 
                    api.defaults.headers.common['token'] = r.data.user.api_token
                    if(values.rememberme){setCookie('grc-remember',r.data.user.api_remember,{maxAge:r.data.max_age});}
                    // setAuthState((state) => ({ ...state, user: r.data}))
                    setAuthState(r.data)
                    setLogged(true)                    
                }
                else{
                    setError(r.data)
                    setLoading(false)
                    setTimeout(()=>{setError(false)},3000)
                }
           }).catch((r)=>{})
    };

    useEffect(()=>{},[authState])
  
    return(
    <div className="container d-flex flex-column">
        <div className="row align-items-top justify-content-center g-0 min-vh-100 mt-3">
            <div className="col-12 col-md-8 col-lg-6 col-xxl-4 py-8 py-xl-0">
                <div className="mb-4 text-center">
                    <a href="/#"><img src={logo} width='200px' className="mb-2" alt="" /></a>
                </div>
                <div className="card smooth-shadow-md">
                    <div className="card-body p-6">

                        <form onSubmit={handleFormSubmit}>
                            <h5>Utilisateur</h5>
                            <Alert show={error?true:false} variant="danger">{error && error.message}</Alert>
                            <FormControl fullWidth sx={{mt: 1,mb: 1}}>
                                <InputLabel htmlFor="email">Courriel</InputLabel>
                                <OutlinedInput
                                    required
                                    id="email"
                                    value={values.email}
                                    onChange={handleChange('email')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton 
                                                edge="end" >
                                                    <FontAwesomeIcon icon={faAt} />
                                            </IconButton> 
                                        </InputAdornment>
                                    }
                                    label="Courriel"
                                    title="Saisir le courriel"
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{mt: 1, mb: 1}} variant="outlined">
                                <InputLabel htmlFor="password">Mot de passe</InputLabel>
                                <OutlinedInput
                                    required
                                    id="password"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleChange('password')}
                                    endAdornment={
                                    <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        <FontAwesomeIcon icon={values.showPassword ?faEyeSlash : faEye} /> 
                                    </IconButton>
                                </InputAdornment>
                                }
                                label="Mot de passe"
                                title="Saisir le mot de passe"
                                />
                            </FormControl>

                            <div className="d-lg-flex justify-content-between align-items-center my-2">
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="rememberme" checked={values.rememberme} onChange={handleCheck("rememberme")} />
                                    <label className="form-check-label" htmlFor="rememberme">Se souvenir de moi</label>
                                </div>
                                <div>
                                    <button className="btn btn-primary" type="submit" disabled={loading} >Connection</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>        
    )
}

LogIn.propTypes = {
    setLogged : PropTypes.func.isRequired,
    setCookie : PropTypes.func.isRequired
}