import { useEffect,useState,useContext } from "react"
import api from '../../utils/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faPlus, faTrash, faPen,faPrint,faFileExcel, faSpinner } from "@fortawesome/free-solid-svg-icons"

import { AuthContext } from '../app/AuthContext'
import Modal from 'react-bootstrap/Modal'

/* ProviderListHeader
 * @param {*} param0 
 * @returns 
 * Description :
 * HandleExport : Permet l'exportation des fiches des entreprises en récupérant
 * Les parametres passés lors de la recherche 
 *****************************************************************************/
export default function ProviderListHeader({company, setCompany, companies,setCompanies,...props}){
    const [authState, setAuthState] = useContext(AuthContext); 
    
    // Option par défaut de la fenêtre de téléchargement du fichier
    const [optExport, setOptExport] = useState({
        onCancel:()=>{setOptExport({...optExport,show:false})},
    }) 

    const handleExport = (e)=>{
        e.preventDefault()
        const params = {
            params : {...props.search,
                user : authState.user.id,
                paginate : false
            }
        }

        setOptExport({...optExport,show:true});
        api.get(`/export/providers`,params)
        .then((resp)=>{setOptExport({...optExport,url:resp.data.url,show:true})})
    }


    useEffect(()=>{},[])

    return (
    <nav className="nav d-flex nav-pills">
        <a href="/#" className="ps-1 flex-grow-1 fs-4 text-decoration-none text-reset"  data-bs-toggle="tab" data-bs-target="#home" role="tab">Liste des  relations / fournisseurs</a> 
        <a href="/#" className="nav-link my-1 active" data-bs-toggle="tab" data-bs-target="#create" role="tab" title="Chercher des fiches"><FontAwesomeIcon icon={faSearch} /></a>
        <a href="/#" className="nav-link my-1 " data-bs-toggle="tab" data-bs-target="#create" role="tab" title="Créer une fiche"><FontAwesomeIcon icon={faPlus} /></a>
        <a href="/#" className="nav-link my-1" data-bs-toggle="tab" data-bs-target="#update" role="tab"  title="Editer la fiche"><FontAwesomeIcon icon={faPen} /></a>
        <a href="/#" className="nav-link my-1" data-bs-toggle="tab" data-bs-target="#delete" role="tab"  title="Supprimer la fiche"><FontAwesomeIcon icon={faTrash} /></a>
        <a href="/#" className="nav-link my-1" data-bs-toggle="tab" data-bs-target="#print" role="tab" title="Imprimer la fiche"><FontAwesomeIcon icon={faPrint} /></a>
        <a href="/#" onClick={handleExport} className="nav-link my-1" data-bs-toggle="tab" data-bs-target="#export" role="tab" title="Exporter Au format CSV" ><FontAwesomeIcon icon={faFileExcel} /></a>
        {optExport.show && <BoxExport optExport={optExport}/>}
    </nav>
    )
}

/* BoxExport
 * Fenêtre de confirmation en cas de suppression
 * @param {*}  optExport
 * @returns 
 *****************************************************************************/
function BoxExport({optExport}){
    const [loading, setLoading] = useState(false)     

    const handleCancel = () => {
        optExport.onCancel()
    }

    return(
        <Modal show={optExport.show}  onHide={handleCancel} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>Export au format CSV</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p className="text-center fs-4 text-muted">
                { !optExport.url ? 
                <FontAwesomeIcon icon={faSpinner} spin/> : 
                <><FontAwesomeIcon icon={faFileExcel} />  <br/> <a className="text-reset" href={optExport.url} download onClick={handleCancel}>Télécharger le fichier</a></> 
                }
            </p>
        </Modal.Body>
    </Modal> 
    )    
}