import React, { useEffect,useContext } from 'react'
import { AuthContext } from '../app/AuthContext'

import {NavLink} from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons"

import { faHome,faUser , faEnvelope, faCircleUser, faHouseUser, faInfinity,  faIdCard, faCogs, faAddressBook, faUserGear} from '@fortawesome/free-solid-svg-icons'

import Icon from '@mdi/react'
import { mdiAccountSearch } from '@mdi/js';

import BoxProfile from './settings/Boxprofile'
import BoxLogout from './BoxLogout'
import BoxSettings from './settings/BoxSettings'
import BoxImportCompany from './Utils/Import/BoxImportCompany'

//import { faUser,faSquare } from '@fortawesome/free-regular-svg-icons'
//https://www.digitalocean.com/community/tutorials/how-to-use-font-awesome-5-with-react-fr


export default  function AppNav({logout}){
    const [authState] = useContext(AuthContext);

    const badge = {
        fontSize:'2.5em',
        top:'-5px',
        right:'-20px'
    }

    useEffect(()=>{},[])

    return (
    <header className="sticky-top mb-1 shadow">
        <nav className="navbar navbar-expand-lg  navbar-dark bg-primary p-0 navbar-fixed-top">
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a href="/#" className="nav-link" data-bs-toggle="offcanvas" data-bs-target="#appMenu">
                            <FontAwesomeIcon icon={faBars}  size="2x" fixedWidth title='Afficher / Masquer les menus'/>
                        </a>
                    </li>                    
                    <li className="nav-item">
                        <NavLink className="nav-link" to='/'><FontAwesomeIcon icon={faHouseUser} size="2x" fixedWidth  /></NavLink>
                    </li>
                    <li className="nav-item" title="Carnet d'adresses">
                        <NavLink className="nav-link" to='/provider'><FontAwesomeIcon icon={faAddressBook}  size="2x" fixedWidth /></NavLink>
                    </li>
                    <li className="nav-item" title="Outils de prospection">
                        <NavLink className="nav-link" to='/provider'><FontAwesomeIcon icon={faUserGear} size="2x" fixedWidth /></NavLink>
                    </li>

                    <BoxImportCompany/>

                    {/* <li className="nav-item">
                        {/* <NavLink className="nav-link" to='/infinite'><FontAwesomeIcon icon={faInfinity}  size="2x" fixedWidth  /></NavLink> */}
                        {/* <a href="/#" className="nav-link" data-bs-toggle="offcanvas" data-bs-target="#offcanvasEnd"><FontAwesomeIcon icon={faCogs}  size="2x" fixedWidth /></a> */}
                    {/* </li>  */}

                    {/* <li className="nav-item">
                        <NavLink className="nav-link" to='/companies'><FontAwesomeIcon icon={faHome}  size="2x" fixedWidth  /></NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink className="nav-link" to='/form'><FontAwesomeIcon icon={faIdCard}  size="2x" fixedWidth  /></NavLink>
                    </li> 
                    <li className="nav-item">
                        <a href="/#" className="nav-link" data-bs-toggle="offcanvas" data-bs-target="#offcanvasEnd"><FontAwesomeIcon icon={faCogs}  size="2x" fixedWidth /></a>
                    </li>
                    <li className="nav-item">
                        <a href="/#" className="nav-link" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop"><Icon path={mdiAccountSearch} size="35" title='Rechercher' /></a>
                    </li> */}


                    {/* <li className="nav-item">
                        <NavLink className="nav-link" to='/other1'>
                            <span className="fa-layers fa-fw">
                                <FontAwesomeIcon icon={faEnvelope} size="2x" transform="down-3"/>
                                <span className="fa-layers-counter bg-danger" style={badge}>0</span>
                            </span>                            
                        </NavLink>
                    </li> */}
                </ul>
            </div>
            {authState.user && <FullName authState={authState} />}
            <div className="flex-shrink-0 dropdown me-2">
                <a href="/#" className="d-block navbar-text text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <FontAwesomeIcon icon={faCircleUser}  size="2x" fixedWidth title='Afficher / Masquer les menus'/>
                </a>
                <ul className="dropdown-menu text-small shadow dropdown-menu-end" >
                    <BoxProfile />
                    <BoxSettings />
                    <li><hr className="dropdown-divider" /></li>
                    <BoxLogout logout={logout} /> 
                </ul>
            </div>
        </nav>
    </header>
    )
}

/* FULLNAME
 * Retourne le nom complet de l'utilisateur en utilisant les informations
 * contenu dans le AuthContext  
 * @param {user String} param0 
 * @returns 
 *****************************************************************************/
function FullName({authState}){
    return(
    <span className="navbar-text me-2">
        {authState.identity.firstname} {authState.identity.lastname}
    </span>        
    )
}  
