import React, {useRef,useCallback} from "react"
import api from '../../utils/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotate, faPrint } from "@fortawesome/free-solid-svg-icons"

export default function ProviderListFooter({companies,setCompanies,...props}) {
        const refType = useRef();
        const refValue = useRef();

        const handleSubmit = useCallback((e)=>{
            e.preventDefault()
            const config = {
                params :{
                    paginate: true, 
                    [refType.current.value] : refValue.current.value
                }
            }

            api.get(`providers`,config)
            .then(r=>{setCompanies(r.data)})               
            .then(()=>{props.setSearch(config.params)})               

        },[setCompanies])
    
        const handleRefresh = useCallback((e)=>{
            refType.current.value  = "name"       
            refValue.current.value = "" 
            
            api.get("providers?paginate=true")
            .then(r=>{setCompanies(r.data)}) 
            .then(()=>{props.setSearch({})})     
        },[setCompanies])
    
    //    useEffect(()=>{console.log("HomeListFooter companies",companies)},[])        
    
        return(
        <div className="card-footer p-1">
            <div className="row">
                <div className="col-10">
                <form className="row g-1" onSubmit={handleSubmit}>
                    <div className="col-auto">
                        <select id="provider-search-select" ref={refType} className="form-select form-select-sm">
                            <option value="name">Nom de l'entreprise</option>
                            <option value="zip">Code postal</option>
                            <option value="city">Commune</option>
                            <option value="email">Email</option>
                            <option value="phone">Téléphone</option>
                            <option value="mobile">Mobile</option>
                            <option value="fax">Télécopie</option>
                        </select>
                    </div>
                    <div className="col-auto">
                        <input id="provider-search-input" ref={refValue}  type="text" className="form-control form-control-sm" placeholder="Zone de recherche"/>
                    </div>
                    
                    <div className="col-auto">
                        <button type="button" className="btn btn-sm btn-primary" title="Rafraichir la zone de recherche" onClick={handleRefresh}>
                            <FontAwesomeIcon icon={faRotate} />
                        </button>
                    </div>
    
                    <div className="col-auto">
                        <button type="submit" className="btn btn-sm btn-primary" title="Lancer la recherche" >ok</button>
                    </div>
    
                    <div className="col-auto">
                        <label >Affichage : {companies.total} entreprise(s)</label>
                    </div>
                </form>
                </div>
                <div className="col-2 text-end">
                    {/* <a href="/#" className="btn btn-primary btn-sm" title="Impression de la fiche entreprise"><FontAwesomeIcon icon={faPrint} /></a> */}
                </div>
            </div>
        </div>  
        )
    }