import  {useEffect, useState} from 'react';
import {useCurrentHeight} from "../../utils/resizeListener"

import AppMenu from "../app/appMenu";

import ProviderTabs from './ProviderTabs';
import ProviderList from './ProviderList';
import ProviderListHeader from './ProviderListHeader';
import ProviderListFooter from './ProviderListFooter';

export default function Provider(){
    const styles = {...useCurrentHeight(430),...{marginBottom:25}}
     const [company, setCompany] = useState({})
     const [companies, setCompanies] = useState({})
     const [search, setSearch] = useState({})

    useEffect(()=>{},[])

    return (
    <div className="container-fluid">
        <div className="row">
            <AppMenu />
            <main className="px-md-1">
                <ProviderTabs company={company?company:null} setCompany={setCompany}/>
                <div className="card fixed-bottom" style={styles}>
                    <ProviderListHeader search={search}/>
                    <ProviderList company={company} setCompany={setCompany} companies={companies} setCompanies={setCompanies} />
                    <ProviderListFooter companies={companies} setCompanies={setCompanies} setSearch={setSearch}/>
                </div>
            </main>
        </div>
    </div>
    )


}