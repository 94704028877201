import React, { useEffect, useState, useRef, useCallback  } from 'react';
import api from '../../../utils/api';
import { isEmpty } from '../../../utils/miscellaneous';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrash, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons"

import Box from '@mui/material/Box';

import SelectCivilite from '../../../components/app/SelectCivilite';
import SelectDroit from '../../../components/app/SelectDroit';
import TextBasic from '../../../components/html/input/TextBasic';
import PhoneBasic from '../../../components/html/input/PhoneBasic';
import MailBasic from '../../../components/html/input/MailBasic';
import DateBasic from '../../../components/html/input/DateBasic';
import PasswordIcon from '../../../components/html/input/PasswordIcon';

/* SettingsUsers
 * Affiche la liste des utilisateurs et permet leurs gestions
 * Affiche le nombre total des utilisateurs
 * Recherche des utilisateurs par leur nom  
 **************************************************************************/
export default function SettingsUsers({active}){
    const container = useRef()
    const search = useRef()
    // Recupération des donnees du serveur
    const [records, setRecords] = useState([])
    
    // Option par défaut de la fenêtre modal
    const [optBox, setOptBox] = useState({
        id:0,
        onCancel:()=>{setOptBox({...optBox,show:false})},
        onValid:()=>{
            fnLoad()
            setOptBox({...optBox,show:false})
        }
    })

    // Option par défaut de la fenêtre confirmation de supression
    const [optConfirm, setOptConfirm] = useState({
        id:0,
        onCancel:()=>{setOptConfirm({...optConfirm,show:false})},
        onValid:()=>{
            fnLoad()
            setOptConfirm({...optConfirm,show:false})
        }
    })

    // Lorsque le tab est actif chargement/ rechargement de la liste
    // Lorsque une recherche est lancé chargement de la liste
    // La ligne de commentaire permet la suppression du WARNING levé
    // lors de la compilation        
    useEffect(()=>{
        if(active === `#${container.current.id}`){fnLoad()}
    // eslint-disable-next-line react-hooks/exhaustive-deps        
    },[active,search])

    const fnLoad = ()=>{
        api.get(`/users/card?fullname=${search.current.value}`)
        .then((resp)=>{setRecords(resp.data)})
    }

    const fnInsert = (e,row)=>{
        setOptBox({...optBox,
            action:'insert',
            id:0,
            show:true,
        })
    }    

    const fnUpdate = (e,row)=>{
        setOptBox({...optBox,
            action:'update',
            id:row.id,
            show:true,
        })
    }

    const fnDelete = (e,row)=>{
        setOptConfirm({...optConfirm,
            action:'delete',
            id:row.id,
            show:true,
        })
    }    

    const fnSearch = (e)=>{
        e.preventDefault()
        fnLoad()
    }



    return(
    <div ref={container} className="tab-pane fade  h-100" id="settings-users" role="tabpanel">     
        <div className="card h-100">
            <div className="card-header fw-bold">
            <div className="row">
                    <div className="col-10">
                        Liste des utilisateurs
                    </div>
                    <div className="col-2 text-end">
                        <span className="badge bg-warning">{records.length}</span> 
                    </div>
                </div>        
            </div>
            <div className="card-body scrollY p-1" style={{maxHeight:210}}>
                <ul className="list-group list-group-flush">
                {(isEmpty(records)) ? "" : records.map((record, index) => (<Row key={index} value={record} onUpdate={fnUpdate} onDelete={fnDelete}/>))}
                </ul>
            </div>
            <div className="card-footer">
                <div className="row">
                    <div className="col-4">
                        <form className="row g-1" onSubmit={fnSearch}>
                            <input ref={search} type='search' className='form-control form-control-sm' placeholder='Zone de recherche'/>
                        </form>
                    </div>
                    <div className="col-8 text-end">
                        <button className='btn btn-sm btn-primary' onClick={fnInsert} title='AJouter un utilisateur'><FontAwesomeIcon icon={faPlus} /></button> 
                    </div>
                </div>
            </div>
        </div>
        {optBox.show && <BoxModal optBox={optBox} setOptBox={setOptBox}/>}
        {optConfirm.show && <BoxConfirm optConfirm={optConfirm}/>}
    </div>
    )
}

/* ROW
 * Lignes du tableau
 *****************************************************************************/
function Row({value, onUpdate, onDelete}){
    const row = useRef()

    const handleUpdate = useCallback((e)=>{
        onUpdate(e,row.current)
    },[onUpdate])

    const handleDelete = useCallback((e)=>{
        onDelete(e,row.current)
    },[onDelete])
    
    return(
    <li ref={row} id={value.id} className="list-group-item row-striped py-1 px-1">
        <div className="row">
            <div className="col text-truncate p-default">{value.droit.value}</div>
            <div className="col text-truncate p-default">{value.firstname} {value.lastname}</div>
            <div className="col text-truncate p-default">{value.email}</div>
            <div className="col-auto pe-1"><button className='btn btn-sm btn-primary' onClick={handleUpdate}><FontAwesomeIcon icon={faEdit} /></button></div>
            <div className="col-auto ps-1"><button className='btn btn-sm btn-primary' onClick={handleDelete}><FontAwesomeIcon icon={faTrash} /></button></div>
        </div>    
    </li>   
    )
}    

/* BoxModal
 * Fenêtre de l'utilisateur
 *****************************************************************************/

function BoxModal({optBox,...props}){
    const [errors, setErrors] = useState({})         
    const [values, setValues] = useState(null)         
    const [loading, setLoading] = useState(false) 

    const handleChange = (e) =>{
        const {id, value} = e.target
        setValues({...values,[id]: value})
    }
    
    const handleCancel = () => {
        optBox.onCancel()
    }
    
    /**
     * Test l'ensemble des selecteurs du formulaire pour autoriser la validation
     * @param {*} id 
     * @returns 
     */
    const isValid = (id)=>{
        let result = true
        const form = document.querySelector(id)    
        const elts = form.querySelectorAll("[required]")
              elts.forEach((elt,i)=>{
                if(elt.name !== undefined && !elt.validity.valid) result = false 
              })
        return result
    } 

    const fnUpdate = ()=>{
        api.put(`users/${optBox.id}/card`,values)
        .then((resp)=>{optBox.onValid()})
        .catch((e)=>{})
    }

    const fnCreate = ()=>{
        api.post(`users/card`,values)
        .then((resp)=>{optBox.onValid()})
        .catch((e)=>{})
    }

    /* Verification de l'existence du mail en cas de création, un mail doit être
     * unique car c'est lui qui sert à l'autentification lors de la connexion
     * En cas de modification permet de modifier le mot de passe mais verifie 
     * toujours si le mail est unique.  
     * @param {*} callBack 
     **************************************************************************/
    const fnCheckEmail = (callBack)=>{
        api.get(`users/email`,{params:{value:values.exchange.email}})
        .then((r)=>{
            if(r.data && r.data.id !== values.id) {
                setErrors({...errors,email:'Ce Courriel est déjà présent !'})
                const form = document.querySelector('#SettingsUsers')
                form.querySelector('#tabs-profile-tab-exchange').click()
                setTimeout(()=>{form.querySelector('#email').focus()},100)
            }
            else{callBack()} 
        })        
    }

    const handleValid = () => {
        if(!isValid('#SettingsUsers')) return
        fnCheckEmail(()=>{
            setLoading(true)
            if(optBox.id > 0)fnUpdate()
            else fnCreate()               
        })
    }

    /* Chargement des données
     *************************************************************************/   
    useEffect(()=>{
        api.get(`users/${optBox.id}/card?genres=true`)
        .then((resp)=>{setValues(resp.data)}) 
    },[optBox])

    return(
    <Modal id="SettingsUsers" show={optBox.show}  onHide={handleCancel} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>Utilisateur</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {values && <TabsProfile setErrors={setErrors} errors={errors} values={values} setValues={setValues} height={224} />}
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel}>Annuler</Button>
            <Button variant="primary" disabled={loading}  onClick={handleValid}>valider</Button>
        </Modal.Footer>
    </Modal> 
    )
}

/* TabsProfile
 * @param {*} props 
 * @returns 
 *****************************************************************************/
function TabsProfile({values, setValues,...props}) {
    const [key, setKey] = useState('identity');

    const [droits] = useState(values.droits) 
    const [genres] = useState(values.genres)

    const [identity, setIdentity] = useState(values.identity || {}) 
    const [adresse, setAdresse] = useState(values.adresse || {}) 
    const [exchange, setExchange] = useState(values.exchange || {}) 
    const [user, setUser] = useState(values.user || {}) 

    useEffect(()=>{
        setValues((values)=>( {...values,
            identity : identity,
            adresse : adresse,
            exchange : exchange,
            user : user,
        }))
    },[setValues, identity, adresse, exchange,user])

    return (
    <Tabs
        id="tabs-profile"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="identity" title="Identité">
            <Identity genres={genres} identity={identity} setIdentity={setIdentity} height={props.height}/>
        </Tab>
        <Tab eventKey="adresse" title="Adresse">
            <Adresse adresse={adresse} setAdresse={setAdresse} height={props.height}/>
        </Tab>
        <Tab eventKey="exchange" title="Communication">
            <Exchange errors={props.errors}  exchange={exchange} setExchange={setExchange} height={props.height}/>
        </Tab>
        <Tab eventKey="user" title="Autre">
            <Other droits={droits} user={user} setUser={setUser} height={props.height}/>
        </Tab>
    </Tabs>
    );
  }

/* Identity
 * @param {*} param0 
 * @param {*} props 
 * @returns 
 *****************************************************************************/
function Identity({errors, genres,identity,...props}){
    const sx = {height:props.height,'& .MuiTextField-root': {mb:1, mt:1}}

    const handleChange = function(e){
        const {id, value} = e.target
        props.setIdentity({...identity,[id]: value})
    } 

    useEffect(()=>{},[])

    return(
    <Box component="form" sx={sx} noValidate autoComplete="off">  
        <SelectCivilite id="genre_id" label="Civilité" value={identity.genre_id} onChange={handleChange} genres={genres} required/> 
        <TextBasic id="firstname" label="Prénom" value={identity.firstname} onChange={handleChange} required/>  
        <TextBasic id="lastname" label="Nom" value={identity.lastname} onChange={handleChange} required/>  
        <DateBasic id="birthday" label="Date de naissance" value={identity.birthday}  onChange={handleChange} />
    </Box>        
    )
}

/* Adresse
 * @param {*} param0 
 * @param {*} props 
 * @returns 
 *****************************************************************************/
function Adresse({adresse,...props}){
    const sx = {height:props.height,'& .MuiTextField-root': {mb:1, mt:1}}
    
    const handleChange = (e) =>{
        const {id, value} = e.target
        props.setAdresse({...adresse,[id]: value})
    } 
    
    return(
    <Box component="form" sx={sx} noValidate autoComplete="off">
        <TextBasic id="street" label="Adresse" value={adresse.street} onChange={handleChange} />
        <TextBasic id="extra" label="Complément" value={adresse.extra}  onChange={handleChange} />
        <TextBasic id="zip" label="Code postal" value={adresse.zip} onChange={handleChange} />
        <TextBasic id="city" label="Commune" value={adresse.city} onChange={handleChange} />
    </Box>
    )
}

/* Exchange
 * @param {*} param0 
 * @param {*} props 
 * @returns 
 *****************************************************************************/
function Exchange({errors, exchange,...props}){
    const sx = {height:props.height,'& .MuiTextField-root': {mb:1, mt:1}}

    const handleChange = (e) =>{
        const {id, value} = e.target
        props.setExchange({...exchange,[id]: value})
    }
    
    return(
    <Box component="form" sx={sx} noValidate autoComplete="off">
        <MailBasic  id="email"  error={errors.email || false}  help={errors.email ? errors.email : ''} label="Courriel"  value={exchange.email} onChange={handleChange} required />
        <PhoneBasic id="phone"  label="Téléphone" value={exchange.phone} onChange={handleChange} />
        <PhoneBasic id="mobile" label="Mobile"  value={exchange.mobile} onChange={handleChange} required />
    </Box>
    )
}

/* Other
 * @param {*} param0 
 * @param {*} props 
 * @returns 
 *****************************************************************************/
function Other({droits, user,...props}){
    const sx = {height:props.height,'& .MuiTextField-root': {mb:1, mt:1}}
    
    const handleChange = (e) =>{
        const {id, value} = e.target
        props.setUser({...user,[id]: value})
    }
    
    return(
    <Box component="form" sx={sx} noValidate autoComplete="off">
        <SelectDroit id="droit_id" label="Droit"  value={user.droit_id} onChange={handleChange} options={droits} required />
        <PasswordIcon id="password"  label="Mot de passe"  value={user.password} onChange={handleChange} required />
    </Box>
    )
}

/* BoxConfirm
 * Fenêtre de confirmation en cas de suppression
 * @param {*}  optConfirm
 * @returns 
 *****************************************************************************/
function BoxConfirm({optConfirm}){
    const [loading, setLoading] = useState(false)     
    const handleCancel = () => {
        optConfirm.onCancel()
    }
    
    const handleValid = () => {
        setLoading(true)
        api.delete(`/users/${optConfirm.id}/card`)
        .then((resp)=>{optConfirm.onValid()})
    }    

    return(
        <Modal show={optConfirm.show}  onHide={handleCancel} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>Attention</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            Confirmez vous la suppression ?
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel}>Annuler</Button>
            <Button variant="primary" disabled={loading}  onClick={handleValid}>valider</Button>
        </Modal.Footer>
    </Modal> 
    )    
}