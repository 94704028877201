
import React, {useState} from 'react';
import TextField from '@mui/material/TextField'

const TextBasic = function(props){
    const [value, setValue] = useState(props.value || '')

    const handleChange =(e)=>{
        e.target.id = props.id
        setValue(e.target.value)
        props.onChange(e)
    }    

return(
    <TextField 
        type="date"
        fullWidth
        id={props.id}
        name={props.id}
        label={props.label}
        disabled={props.disabled}
        InputProps={{readOnly: props.readOnly}}
        error={props.error  ? props.error : props.required ? !value : false } 
        required={props.required} 
        variant="outlined" 
        size="small" 
        value={value} 
        onChange={handleChange}
        InputLabelProps={{shrink: true}}
        helperText={props.help}
    />  
)


}
export default React.memo(TextBasic)