import { useCallback, useEffect, useState } from "react"

/**
* Sources : 
* - https://dev.to/vitaliemaldur/resize-event-listener-using-react-hooks-1k0c
* - https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
* Documentation :
* Enregistrer la hauteur de la fenêtre actuelle dans l'objet d'état
* dans ce cas, useEffect ne s'exécutera qu'une seule fois car il n'a aucune dépendance.
* Ajout d'un timeout pour le mécanisme anti-rebond 
* Empêcher l'exécution du setTimeout précédent
* Changer la hauteur de l'objet d'état après 150 millisecondes
* Définition de  l'écouteur de redimensionnement
* fonction de nettoyage
* Suppression de l'écouteur de redimensionnement
* @returns 
*/
export  function useCurrentHeight(dimention,tps) {
    const getHeight = useCallback(
        () => window.innerHeight  - dimention || 0
    ,[dimention])    

    let [height, setHeight] = useState(getHeight());

    useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => setHeight(getHeight()), tps || 150);
    };
    
    window.addEventListener('resize', resizeListener);
    
    return () => { window.removeEventListener('resize', resizeListener);}
    }, [tps,getHeight])

    return {height:height};
}

/**
* Sources : 
* - https://dev.to/vitaliemaldur/resize-event-listener-using-react-hooks-1k0c
* - https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
* Documentation :
* Enregistrer la largeur de la fenêtre actuelle dans l'objet d'état
* dans ce cas, useEffect ne s'exécutera qu'une seule fois car il n'a aucune dépendance.
* Ajout d'un timeout pour le mécanisme anti-rebond 
* Empêcher l'exécution du setTimeout précédent
* Changer la largeur de l'objet d'état après 150 millisecondes
* Définition de  l'écouteur de redimensionnement
* fonction de nettoyage
* Suppression de l'écouteur de redimensionnement
* @returns 
*/
export  function useCurrentWidth(dimention,tps) {
    const getWidth =  useCallback(
        () => window.innerWidth - dimention || 0
    ,[dimention])

    let [width, setWidth] = useState(getWidth());

    useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => setWidth(getWidth()), tps || 150);
    };
    
    window.addEventListener('resize', resizeListener);
    
    return () => { window.removeEventListener('resize', resizeListener);}
    }, [tps,getWidth])

    return {width:width};
}


export  function useCurrentDimentions(width,height,tps) {
    const getDimentions = useCallback(
        () => ({width :window.innerWidth-width || 0 , height :window.innerHeight-height || 0})
    ,[width,height])

    let [dimentions, setDimentions] = useState(getDimentions());

    useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => setDimentions(getDimentions()), tps || 150);
    };
    
    window.addEventListener('resize', resizeListener);
    
    return () => { window.removeEventListener('resize', resizeListener);}
    }, [tps,getDimentions])

    return dimentions;
}