/**
 * Permet de vérifier si un objet est vide
 * @param {*} obj 
 * @returns boolean
 */
 export function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

/**
 * Permet de transformer une date au format "dd/mm/YYYY"
 * @param {*} obj 
 * @returns String
 */
export function toFrench(obj){
    return new Date(obj).toLocaleDateString("fr-FR")
}