import { useEffect, useState } from "react"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'


export default function ProviderTabInfo({company, ...props}){
    const styles = {height:props.height,minHeight:props.height}
    const variant = "standard"
    
    const formProps={
        //'& > :not(style)': { m: 1, width: '25ch' },
        '& > :not(style)': { m: 1, width: '100%' },
      }
    
    const inputProps={
        readOnly: true,
    }
    
    useEffect(()=>{
        console.log("ProviderTabInfo->",company)
      },[])
    
    return(
    <div id="information" role="tabpanel" className="tab-pane fade show active h-100 overflowY-scroll" style={styles}>
            <div className="row">
                <div className="col">
                    <TextField label="Nom de l'entreprise" variant={variant} size="small" InputProps={inputProps} value={company.name || " "}/>
                </div>
                <div className="col">
                    <TextField label="Mail de l'entreprise" variant={variant} size="small" InputProps={inputProps} value={company.mail || " "}/>
                </div>
            </div>
        <Box component="form" sx={formProps} noValidate autoComplete="off">
            {/* <TextField label="Chef d'entreprise" variant={variant} size="small" InputProps={inputProps} value={company.boss || " "}/> */}
            {/* <TextField label="Confederation" variant={variant} size="small" InputProps={inputProps} value={company.confederation || " "}/> */}
            {/* <TextField label="Code de l'entreprise" variant={variant} size="small" InputProps={inputProps} value={company.code || " "}/> */}
            <TextField label="Adresse de l'entreprise" variant={variant} size="small" InputProps={inputProps} value={company.address || " "}/>
            <TextField label="Code postal / Commune" variant={variant} size="small" InputProps={inputProps} value={company.zip+" / "+company.city}/>

            
            <TextField label="Téléphone de l'entreprise" variant={variant} size="small" InputProps={inputProps} value={company.phone || " "}/>
            <TextField label="Mobile de l'entreprise" variant={variant} size="small" InputProps={inputProps} value={company.mobile || " "}/>
            <TextField label="Télécopie de l'entreprise" variant={variant} size="small" InputProps={inputProps} value={company.fax || " "}/>


            
            {/* <TextField label="Siret/Nafa" variant={variant} size="small" InputProps={inputProps} value={company.siret+" / "+company.nafa}/> */}

            {/* <TextField label="Régime / Statut" variant={variant} size="small" InputProps={inputProps} value={(company.regime>0 ?"Micro-entreprise": company.statut) || " " }/> */}
        </Box>
    </div>
    )
}
