import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordBasic = function(props){
    const [values, setValues] = React.useState({
        amount: '',
        password: props.value || '',
        weight: '',
        weightRange: '',
        showPassword: false,
      });
    
      const handleChange = (prop) => (e) => {
        e.target.id = props.id          
        setValues({ ...values, [prop]: e.target.value });
        props.onChange(e)
      };
    
      const handleClickShowPassword = () => {
        setValues({
          ...values,
          showPassword: !values.showPassword,
        });
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };


    return(
    <FormControl fullWidth  sx={{ mt:1,mb:1}} variant="outlined">
        <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
        <OutlinedInput

        id={props.id}
        name={props.id}
        type={values.showPassword ? 'text' : 'password'}
        value={values.password}
        onChange={handleChange('password')}
        endAdornment={
            <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
            >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
            </InputAdornment>
        }
        label={props.label}
        size="small"
        />
        <FormHelperText>{props.help}</FormHelperText>
    </FormControl>
    )
}
export default React.memo(PasswordBasic)