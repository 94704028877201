import React, {useState,useEffect} from 'react';

import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import {FormLabel, Slider, Stack } from '@mui/material';

const CustomSlider = styled(Slider)({
    color: 'var(--color)',
    '& .MuiSlider-thumb': {
      [`&:hover, &.Mui-focusVisible`]: {
        boxShadow: '0px 0px 0px 8px var(--box-shadow)',
      },
      [`&.Mui-active`]: {
        boxShadow: '0px 0px 0px 14px var(--box-shadow)',
      },
    },
  });

const successVars = {
    '--color': '#4caf50',
    '--box-shadow': 'rgb(76, 175, 80, .16)',
  };
  
const defaultVars = {
    '--color': '#AAAAAA',
    '--box-shadow': 'rgb(200, 200, 200, .16)',
  };

const errorVars = {
    '--color': '#dc3545',
    '--box-shadow': 'rgb(255, 118, 10, .16)',
  };

// Renvoi la valeur sauf dans le cas de zéro renvoi vide  
const toEmpty = (value)=>{
    return value ? value : ""
}

export default function FilterSwitchSlider({...props}){
    // Valeur par défaut 
    const defaultValue = 0    
    const filterValue = props.filter.value ? props.filter.value : defaultValue

    const [value, setValue] = useState(filterValue)
    const [vars, setVars] = useState(defaultVars)
    const [filter,setFilter] = useState({
        event: '',
        id : props.id,
        value : toEmpty(filterValue),
        compare : ""
    })

    // Détermine la couleur en fonction de la valeur 
    // Je n'utilise pas le zéro comme test car je l'ai
    // remplacé par du vide dans le filtre. 
    const switchVars = (value)=>{
        switch(value){
            case -1 : setVars(errorVars)
            break
            case "" : setVars(defaultVars)
            break
            case 1 : setVars(successVars)
            break
        }        
    }

    const handleChange = (e,value)=>{
        setFilter({
             ...filter,
             event:e,
             value : toEmpty(e.target.value)
        })
    }

    const handleOui = (e)=>{
        setFilter({
             ...filter,
             event:e,
             value : 1,
        })
    }

    const handleNon = (e)=>{
        setFilter({
            ...filter,
            event:e,
            value : -1,
       })
   }

    useEffect(()=>{
        setValue(filter.value ? filter.value : 0) 
        switchVars(filter.value)
        props.onChange(filter.event,filter)
    },
    [filter])

    useEffect(()=>{
        if(props.reset) {
            setFilter({
                ...filter,
                value : "",
            })            
        }
    },
    [props.reset])


    return(
    <FormControl
        fullWidth
        variant="standard" 
        sx={{ mb:1, mt:1 }}
    >
        <FormLabel style={{fontSize:"0.8rem"}} id={"label-"+props.id}>{props.label}</FormLabel>
        
        <Stack spacing={3} direction="row" sx={{mb:0}} alignItems="center">
            <span style={{cursor:"pointer"}} onClick={handleNon}>Non</span>
                <CustomSlider style={vars} value={value} onChange={handleChange} step={1} min={-1} max={1} size="small" />
            <span style={{cursor:"pointer"}} onClick={handleOui}>Oui</span>
        </Stack>
    </FormControl>
    )
}