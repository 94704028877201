import { useEffect, useState,useRef,useCallback } from "react"
import api from '../../../utils/api';
import { isEmpty } from '../../../utils/miscellaneous';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrash, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons"

import Box from '@mui/material/Box';

import TextBasic from '../../../components/html/input/TextBasic';
import DateBasic from '../../../components/html/input/DateBasic';
import SelectCivilite from "../../../components/app/SelectCivilite";

export default function HomeTabContact({activeKey,company,...props}){
    const styles = {
        height:props.height - 89,
        minHeight:props.height - 89,
        maxHeight:props.height - 89
   }

    const container = useRef()
    const search = useRef()
    // Recupération des donnees du serveur
    const [records, setRecords] = useState([]) 

    // Option par défaut de la fenêtre modal
    const [optBox, setOptBox] = useState({
        id:0,
        onCancel:()=>{setOptBox({...optBox,show:false})},
        onValid:()=>{
            fnLoad()
            setOptBox({...optBox,show:false})
        }
    })

    // Option par défaut de la fenêtre confirmation de supression
    const [optConfirm, setOptConfirm] = useState({
        id:0,
        onCancel:()=>{setOptConfirm({...optConfirm,show:false})},
        onValid:()=>{
            fnLoad()
            setOptConfirm({...optConfirm,show:false})
        }
    })


    // Lorsque le tab est actif chargement/ rechargement de la liste
    // Lorsque une recherche est lancé chargement de la liste
    // Le paramètre count permet la mise à jour du compteur 
    const fnLoad = (count)=>{
        const params = {
            params:{fullname : search.current.value}
        }

        api.get(`/companies/${company.id}/contacts`,params)
           .then((resp)=>{setRecords(resp.data); return resp.data.length})
           .then((data)=>{if(count) fnCount(data)})
    }

    const fnInsert = (e,row)=>{
        setOptBox({...optBox,show:true,id:0,company:company.id})
    }    

    const fnUpdate = (e,row)=>{
        setOptBox({...optBox,show:true,id:row.id,company:company.id})
    }

    const fnDelete = (e,row)=>{
        setOptConfirm({...optConfirm,show:true,id:row.id,company:company.id})
    }    

    const fnSearch = (e)=>{
        e.preventDefault()
        fnLoad()
    }

    // Permet de modifier le compteur du tab auquel appartient la liste 
    const fnCount = (length)=>{
        document.querySelector("#homeTabBadgeContact").innerHTML = length
    }

    
    // Recharge la liste lorsque l'onglet est actif  
    useEffect(()=>{
        if(activeKey === 'contact') {fnLoad(true)}
      },[activeKey,company])
    
    //       
    return(
    <div name="contact">
        <div className="card h-100">
            <div className="card-header fw-bold">
            <div className="row">
                    <div className="col-10">
                        Liste des contacts
                    </div>
                    <div className="col-2 text-end">
                        <span className="badge bg-warning">{records.total}</span> 
                    </div>
                </div>        
            </div>
            <div className="card-body scrollY p-1" style={styles}>
                <ul className="list-group list-group-flush">
                {(isEmpty(records)) ? "" : records.map((record, index) => (<Row key={index} value={record} onUpdate={fnUpdate} onDelete={fnDelete}/>))}
                </ul>
            </div>
            <div className="card-footer">
                <div className="row">
                    <div className="col-4">
                        <form className="row g-1" onSubmit={fnSearch}>
                            <input ref={search} type='search' className='form-control form-control-sm' placeholder='Zone de recherche'/>
                        </form>
                    </div>
                    <div className="col-8 text-end">
                        <button className='btn btn-sm btn-primary' onClick={fnInsert} title='AJouter un utilisateur'><FontAwesomeIcon icon={faPlus} /></button>
                    </div>
                </div>
            </div>
        </div>
        {optBox.show && <BoxModal optBox={optBox}/>}
        {optConfirm.show && <BoxConfirm optConfirm={optConfirm}/>}
    </div>
    )
}

/* ROW
 * Lignes du tableau
 *****************************************************************************/
function Row({value, onUpdate, onDelete}){
    const row = useRef()

    const handleUpdate = useCallback((e)=>{
        onUpdate(e,row.current)
    },[onUpdate])

    const handleDelete = useCallback((e)=>{
        onDelete(e,row.current)
    },[onDelete])
    
    return(
    <li ref={row} id={value.id} className="list-group-item row-striped py-1 px-1">
        <div className="row">
            <div className="col text-truncate p-default">{value.firstname} {value.lastname}</div>
            <div className="col text-truncate p-default">{value.exchange.email}</div>
            <div className="col text-truncate p-default">{value.exchange.phone}</div>
            <div className="col text-truncate p-default">{value.exchange.mobile}</div>
            <div className="col-auto pe-1"><button className='btn btn-sm btn-primary' onClick={handleUpdate}><FontAwesomeIcon icon={faEdit} /></button></div>
            <div className="col-auto ps-1"><button className='btn btn-sm btn-primary' onClick={handleDelete}><FontAwesomeIcon icon={faTrash} /></button></div>
        </div>    
    </li>   
    )
}  

/* BoxModal
 * Fenêtre de l'utilisateur
 *****************************************************************************/

function BoxModal({optBox}){
    const [values, setValues] = useState(null)         
    const [loading, setLoading] = useState(false) 

    const handleChange = (e) =>{
        const {id, value} = e.target
        setValues({...values,[id]: value})
    }
    
    const handleCancel = () => {
        optBox.onCancel()
    }
    
    /**
     * Validation de la fiche
     * En fonction de l'identifiant  Modification / création d'une fiche
     */
    const handleValid = () => {
        if(values.identity.genre_id && values.identity.firstname && values.identity.lastname){
            setLoading(true)
            if(optBox.id > 0){
                api.put(`companies/contacts/${optBox.id}`,values)
                .then((resp)=>{optBox.onValid()})
            }
            else{
                api.post(`companies/${optBox.company}/contacts`,values)
                .then((resp)=>{optBox.onValid()})
            }   
        }     
    }

    useEffect(()=>{
        // Chargement des données 
        api.get(`companies/contacts/${optBox.id}`)
            .then((resp)=>{setValues(resp.data)})  
    },[optBox])

    return(
    <Modal show={optBox.show}  onHide={handleCancel} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>Utilisateur</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {values && <TabsProfile values={values} setValues={setValues} height={224} />}
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel}>Annuler</Button>
            <Button variant="primary" disabled={loading}  onClick={handleValid}>valider</Button>
        </Modal.Footer>
    </Modal> 
    )
}

/* TabsProfile
 * @param {*} props 
 * @returns 
 *****************************************************************************/
function TabsProfile({values, setValues,...props}) {
    const [key, setKey] = useState('identity');

    const [genres, setGenres] = useState(values.genres || {}) 
    const [identity, setIdentity] = useState(values.identity || {}) 
    const [adresse, setAdresse] = useState(values.adresse || {}) 
    const [exchange, setExchange] = useState(values.exchange || {}) 

    useEffect(()=>{
        setValues((values)=>( {...values,
            identity :  identity,
            adresse :  adresse,
            exchange :  exchange,
        }))
    },[setValues, identity, adresse, exchange])

    return (
    <Tabs
        id="tabs-profile"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="identity" title="Identité">
            <Identity genres={genres} identity={identity} setIdentity={setIdentity} height={props.height}/>
        </Tab>
        <Tab eventKey="adresse" title="Adresse">
            <Adresse adresse={adresse} setAdresse={setAdresse} height={props.height}/>
        </Tab>
        <Tab eventKey="exchange" title="Communication">
            <Exchange exchange={exchange} setExchange={setExchange} height={props.height}/>
        </Tab>
    </Tabs>
    );
  }

/* Identity
 * @param {*} param0 
 * @param {*} props 
 * @returns 
 *****************************************************************************/
function Identity({genres,identity,setIdentity},props){
    const sx = {height:props.height,'& .MuiTextField-root': {mb:1, mt:1}}

    const handleChange = function(e){
        const {id, value} = e.target
        setIdentity({...identity,[id]: value})
    } 

    //useEffect(()=>{console.log(identity.genre_id)},[])

    return(
    <Box component="form" sx={sx} noValidate autoComplete="off">  
        <SelectCivilite id="genre_id" label="Civilité" required value={identity.genre_id} onChange={handleChange} genres={genres} />      
        <TextBasic id="firstname" label="Prénom" required value={identity.firstname} onChange={handleChange} /> 
        <TextBasic id="lastname" label="Nom" required value={identity.lastname} onChange={handleChange} /> 
        <DateBasic id="birthday" label="Date de naissance" value={identity.birthday} onChange={handleChange} />
    </Box>        
    )
}

/* Adresse
 * @param {*} param0 
 * @param {*} props 
 * @returns 
 *****************************************************************************/
function Adresse({adresse,setAdresse,...props}){
    const sx = {height:props.height,'& .MuiTextField-root': {mb:1, mt:1}}
    
    const handleChange = (e) =>{
        const {id, value} = e.target
        setAdresse({...adresse,[id]: value})
    } 
    
    return(
    <Box component="form" sx={sx} noValidate autoComplete="off">
        <TextBasic id="street" label="Adresse" required value={adresse.street} onChange={handleChange} /> 
        <TextBasic id="extra" label="Complément" value={adresse.extra} onChange={handleChange} /> 
        <TextBasic id="zip" label="Code postal" required value={adresse.zip} onChange={handleChange} /> 
        <TextBasic id="city" label="Commune" required value={adresse.city} onChange={handleChange} /> 
    </Box>
    )
}

/* Exchange
 * @param {*} param0 
 * @param {*} props 
 * @returns 
 *****************************************************************************/
function Exchange({exchange,setExchange,...props}){
    const sx = {height:props.height,'& .MuiTextField-root': {mb:1, mt:1}}
    
    const handleChange = (e) =>{
        const {id, value} = e.target
        setExchange({...exchange,[id]: value})
    }
    
    return(
    <Box component="form" sx={sx} noValidate autoComplete="off">
        <TextBasic id="email" label="Courriel" required value={exchange.email} onChange={handleChange} />
        <TextBasic id="phone" label="Téléphone" required value={exchange.phone} onChange={handleChange} />
        <TextBasic id="mobile" label="Mobile" value={exchange.mobile} onChange={handleChange} />
    </Box>
    )
}

/* BoxConfirm
 * Fenêtre de confirmation en cas de suppression
 * @param {*}  optConfirm
 * @returns 
 *****************************************************************************/
function BoxConfirm({optConfirm}){
    const [loading, setLoading] = useState(false)     
    const handleCancel = () => {
        optConfirm.onCancel()
    }
    
    const handleValid = () => {
        setLoading(true)
        api.delete(`/companies/contacts/${optConfirm.id}`)
             .then((response)=>{optConfirm.onValid()})
        optConfirm.onValid()
    }    

    return(
        <Modal show={optConfirm.show}  onHide={handleCancel} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>Attention</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            Confirmez vous la suppression ?
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel}>Annuler</Button>
            <Button variant="primary" disabled={loading}  onClick={handleValid}>valider</Button>
        </Modal.Footer>
    </Modal> 
    )    
}