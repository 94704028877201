import React, { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../AuthContext'
import api from '../../../../utils/api'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUpload } from "@fortawesome/free-solid-svg-icons"

import Upload from "rc-upload"

export default function BoxImportCompany(){
    
    const [show, setShow] = useState(false) 

    return(
    <li className="nav-item">
        <a href="/#" className="nav-link" onClick={setShow} title="Importer les entreprises" ><FontAwesomeIcon icon={faCloudUpload} size="2x" fixedWidth /></a>
        {show && <BoxModal show={show} setShow={setShow} />}
    </li>
    )
}

function BoxModal({show,setShow}){
    const [authState] = useContext(AuthContext);
    const [loading, setLoading] = useState(false)
    const [progress, setProgress]= useState(false)
    const [upload, setUpload]= useState(true)
    const [upgrade, setUpgrade]= useState(false)
    const [stop, setStop]= useState(false)
    const [error, setError]= useState({
        state:false,
        message:"Un erreur est survenue !"
    })

    const props = {
        multiple: false,
        accept: '.xls, .xlsx', //.csv,
        headers:{token:authState.user.api_token},
        action: `${window.BASE_API}import/companies`,
        
        onStart(file) {
            setUpload(false)
            setLoading(true)
        },
        onSuccess(response) {
            setLoading(false)
            setProgress(false)
            setUpgrade(true)
            api.post('/import/companies',{upgrade:true, file:response.file})
            .then(r=>{
                if(r.data.status ==="error"){
                    setError({...error,
                        state:true,
                        message:r.data.message
                    })
                }else{
                    setUpgrade(false)
                    setStop(true)
                }
            })
        },
        onError(err,resp) {
            setError({...error,
                state:true,
                message:resp.message
                //columns:
            })
            setProgress(false)
            setLoading(false)
        },
        onProgress({ percent }, file) {setProgress(parseInt(percent))},
    };


    const handleClose = () =>{setShow(false)} 

    return(
    <Modal id="ImportCompany" show={show}  onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>Importer les entreprises (ISABAT)</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {   
            upload &&    
                <Upload  {...props} >
                    <p className="text-center"> 
                        {!loading && <a className="btn btn-primary" role="button" >Choisir un fichier</a>}
                    </p>
                </Upload>
            }

            {
            progress && 
                <div>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{width:progress+"%"}} aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100}>{progress}%</div>
                    </div>
                    <p className="text-center mt-3">Téléchargement du fichier en cours</p>
                </div>
            }
            
            {
            upgrade && 
                <div>
                    <div className="progress">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width:"100%"}} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></div>
                    </div>
                    <p className="text-center mt-3">Importation des données en cours</p>
                </div>
            }
            
            {
            stop && 
                <p className="text-center font-weight-bold text-primary">Importation des données en cours terminé !</p>
            }

            {
            error.state && 
                <p className="text-center font-weight-bold text-danger">{error.message}</p>
            }

        </Modal.Body>

        <Modal.Footer>
            <Button variant="primary" disabled={loading}  onClick={handleClose}>Fermer</Button>
        </Modal.Footer>
    </Modal>     
    )

}