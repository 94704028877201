import React, { useState, useContext  } from 'react';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlock} from "@fortawesome/free-solid-svg-icons"

import api from '../../utils/api';
import { AuthContext } from './AuthContext';


export default function BoxLogout({logout} ){
    const [show, setShow] = useState(false) 

    return(
    <li>
        <a href="/#" className="dropdown-item" onClick={setShow} ><FontAwesomeIcon icon={faUnlock} /> Déconnection</a>
        {show && <BoxModal show={show} setShow={setShow} logout={logout} />}
    </li>
    )
}

function BoxModal({show,setShow,logout}){
    const [authState] = useContext(AuthContext);
    
    const handleCancel = () =>{setShow(false)} 
    
    const handleValid = () =>{
        api.post('/auth/logout',authState.user)
           .then(logout)
        // .then((r)=>{
        //     logout()
        // }) 
        setShow(false)       
    }
    
    return(
    <Modal show={show}  onHide={handleCancel} backdrop="static" >
        <Modal.Header closeButton>
            <Modal.Title>Important</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            Confirmez-vous la déconnection ?
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel}>Annuler</Button>
            <Button variant="primary" onClick={handleValid}>Valider</Button>
        </Modal.Footer>
    </Modal>        
    )
}   