import React, {useEffect, useState} from "react"

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import ProviderTabInfo from "./ProviderTabInfo"
import ProviderTabContact from "./ProviderTabContact"

import BadgeTabWarning from "../../components/app/BadgeTabWarning"

export default function ProviderTabs({company,...props}){
    const height = 300
    const [key, setKey] = useState('information');

    // A chaque fois qu'une entreprise est chargé activation de l'onglet information 
    useEffect(()=>{setKey("information")},[company])

    return (
    <Tabs
        id="provider-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-1"
      >
        <Tab eventKey="information" title="Information" >
            <ProviderTabInfo activeKey={key} company={company} height={height} /> 
        </Tab>
        <Tab eventKey="contact" title={<BadgeTabWarning id="homeTabBadgeContact" label="Contacts"  value={0} />} >
            <ProviderTabContact activeKey={key} company={company} height={height} />
        </Tab>
    </Tabs>
    )
}