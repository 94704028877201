import React, {useState} from 'react';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';

const SelectBasic = function(props){
    const [value, setValue] = useState(props.value || 0)
    const [placeholder] = useState( props.placeholder === "" ? false : props.placeholder ? props.placeholder : "Sélectionner une option" )


    const handleChange =(e)=>{
        console.log(e.target)
        e.target.id = props.id
        setValue(e.target.value)
        props.onChange(e)
    }    

    
    return(
    <FormControl fullWidth sx={{ mb:1, mt:1 }} required={props.required ? true : false} >
        <InputLabel id="label">{props.label}</InputLabel>
        <Select
            id={props.id}
            name={props.id}
            error={props.error  ? props.error : props.required ? !value : false } 
            value={value}
            labelId="label"
            label={props.label}
            required={props.required} 
            onChange={handleChange}
            size="small"
        >
        {placeholder && <MenuItem disabled value={0} ><em>{placeholder}</em></MenuItem>}
        {props.options && props.options.map((option)=>(<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}        
        </Select>
        <FormHelperText>{props.help}</FormHelperText>
    </FormControl>        
    )

}
export default React.memo(SelectBasic)