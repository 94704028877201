import React, {useState} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSliders} from "@fortawesome/free-solid-svg-icons"

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import SettingsUsers from './SettingsUsers';
//import SettingsGlobal from './SettingsGlobal';
import SettingsConfederations from './SettingsConfederations'
import SettingsAdhesions from './SettingsAdhesions'
import SettingsActivities from './SettingsActivities';
import SettingsSyndicates from './SettingsSyndicate';
import SettingsThemes from './SettingsTheme';
import SettingsNatures from './SettingsNature';
import SettingsRelations from './SettingsRelation';
import SettingsFormes from './SettingsFormes';

const  BoxSettings = function (){
    const [show, setShow] = useState(false) 

    return(
    <li>
        <a href="# " className="dropdown-item" onClick={setShow} ><FontAwesomeIcon icon={faSliders} /> Paramétrages</a>
        {show && <BoxModal show={show} setShow={setShow}/>}
    </li>
    )
}

export default  React.memo(BoxSettings)

function BoxModal({show,setShow}){
    const [active, setActive] = useState("#settings-global")

    const handleClick = (e) =>{
        setActive(e.target.dataset.bsTarget)
    }

    const handleClose = () => {setShow(false)}
    
    return(
        <Modal show={show}  onHide={handleClose} backdrop="static" size="xl">
        <Modal.Header closeButton>
            <Modal.Title>Paramétrages</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <div className="row">
            <div className="col-3" style={{height:300,overflowY:'scroll',overflowX:'hidden' }} >
                <nav className="nav nav-tabs nav-pills flex-column border-0">
                    <a href="/#" className="nav-link active" data-bs-toggle="tab" role="tab" data-bs-target="#settings-global" onClick={handleClick} >Paramètres généraux</a>
                    <a href="/#" className="nav-link" data-bs-toggle="tab" role="tab" data-bs-target="#settings-users" onClick={handleClick}>Utilisateurs / salariés</a>
                    
                    <a href="/#" className="nav-link" data-bs-toggle="tab" role="tab" data-bs-target="#settings-adhesions" onClick={handleClick}>Entreprise (Adhésions)</a>
                    <a href="/#" className="nav-link" data-bs-toggle="tab" role="tab" data-bs-target="#settings-confederations" onClick={handleClick}>Entreprise (Confédérations)</a>
                    <a href="/#" className="nav-link" data-bs-toggle="tab" role="tab" data-bs-target="#settings-syndicates" onClick={handleClick}>Entreprise (Syndicats)</a>
                    <a href="/#" className="nav-link" data-bs-toggle="tab" role="tab" data-bs-target="#settings-activities" onClick={handleClick}>Entreprise (Activités)</a>
                    <a href="/#" className="nav-link" data-bs-toggle="tab" role="tab" data-bs-target="#settings-formes" onClick={handleClick}>Entreprise (Forme juridique)</a>

                    <a href="/#" className="nav-link" data-bs-toggle="tab" role="tab" data-bs-target="#settings-natures" onClick={handleClick}>Fournisseur (Natures)</a>
                    <a href="/#" className="nav-link" data-bs-toggle="tab" role="tab" data-bs-target="#settings-relations" onClick={handleClick}>Fournisseur (Relations)</a>
                    
                    <a href="/#" className="nav-link" data-bs-toggle="tab" role="tab" data-bs-target="#settings-themes" onClick={handleClick}>Evènement (Thèmes)</a>
                    
                </nav>
            </div>
            <div className="col-9">
                <div className="tab-content h-100" > 
                    {/*<SettingsGlobal active={active} />*/}
                    <SettingsUsers active={active} />
                    <SettingsAdhesions active={active} />
                    <SettingsConfederations active={active} />
                    <SettingsSyndicates active={active} />
                    <SettingsActivities active={active} />
                    <SettingsFormes active={active} />
                    <SettingsThemes active={active} />
                    <SettingsNatures active={active} />
                    <SettingsRelations active={active} />
                </div>
            </div>
        </div>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>Fermer</Button>
        </Modal.Footer>
    </Modal>         
    )    
}