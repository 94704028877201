export default function AppMenu(){
    return(
        <nav id="appMenu" className="offcanvas bg-ligth offcanvas-start" tabIndex="-1" style={{width:250,top:51}}>
            <div className="offcanvas-header">
                <h5>Menu principal</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">            
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="/#">
                            <span data-feather="home"></span>
                            Dashboard
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/#">
                            <span data-feather="file"></span>
                            Orders
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/#">
                            <span data-feather="shopping-cart"></span>
                            Products
                        </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="/#">
                        <span data-feather="users"></span>
                        Customers
                    </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="/#">
                        <span data-feather="bar-chart-2"></span>
                        Reports
                    </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="/#">
                        <span data-feather="layers"></span>
                        Integrations
                    </a>
                    </li>
                </ul>

                <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span>Saved reports</span>
                    <a className="link-secondary" href="/#" aria-label="Add a new report">
                    <span data-feather="plus-circle"></span>
                    </a>
                </h6>
                <ul className="nav flex-column mb-2">
                    <li className="nav-item">
                    <a className="nav-link" href="/#">
                        <span data-feather="file-text"></span>
                        Current month
                    </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="/#">
                        <span data-feather="file-text"></span>
                        Last quarter
                    </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="/#">
                        <span data-feather="file-text"></span>
                        Social engagement
                    </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="/#">
                        <span data-feather="file-text"></span>
                        Year-end sale
                    </a>
                    </li>
                </ul>
            </div>
        </nav>        
    )
}