/**
 * Permet d'executer une fonction de rappelle apres la fin d'un scroll sur un element
 * 1- Assurez-vous qu'un rappel valide a été fourni
 * 2- Si un élement est passes par reference modification de la variable container
 * 3- Configurer la variable de défilement
 * 4- Effacer notre délai d'attente tout au long du défilement
 * 5- Définir un délai d'attente à exécuter après la fin du défilement  
 */

export default function scrollStopListener(callback, element, timeout) {
    if (!callback || typeof callback !== 'function') return;
    if(element.current || typeof  element.current === 'object') element = element.current;
    let handle = null;

    let onScroll = function(e) {
        if (handle) {clearTimeout(handle);}
        handle = setTimeout(callback, timeout || 200); // default 200 ms
    };

    element.addEventListener('scroll', onScroll);
    return function() { element.removeEventListener('scroll', onScroll);};
}   