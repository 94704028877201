import { useCallback, useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


import './App.css';
import AppNav from './routes/app/appNav';
import Home from './routes/home/home';
import AppFooter from './routes/app/AppFooter';
import SidebardTop from './routes/app/SidebarTop';
import SidebardEnd from './routes/app/SidebarEnd';
import LogIn from './routes/login/login';
import api from './utils/api';
import { useCookies } from 'react-cookie';
import {AuthProvider} from './routes/app/AuthContext'
import Provider from './routes/Provider/Provider';




function App() {
    // Cookie rememberme permettant la connection automatique
    const [cookies, setCookie ,removeCookie] = useCookies(null);
    const [logged, setLogged] = useState(null)

    useEffect(()=>{console.log(logged)},[logged])  

    /**************************************************************************
     * AUTOLOGIN
     * Vérification de l'existence d'un cookie de connection automatique
     * Si il existe, requête  permettant
     * - Ajout du token pour l'ensemble des requête   
     * - Identification de l'utilisateur par l'utilisation d'un callBack 
     * - Chargement de la page d'accueil   
     *************************************************************************/
    const autoLogin  = useCallback((callback)=>{
        if(cookies['grc-remember']){
            api.post('/auth/remember',{'cookie':cookies['grc-remember']})
                .then((r)=>{
                    api.defaults.headers.common['token'] = r.data.user.api_token
                    callback(r.data)
                    setLogged(true)
                })
                .catch(()=>{})
        }
    },[cookies])

    /**************************************************************************
     * HANDLELOGOUT
     * Permet la déconnection de l'utilisateur
     * Lorsque un utilisateur click dans la barre outils principale sur le menu
     * deconnexion affichage d'une fenêtre de confirmation. 
     * Si l'utilisateur confirme sont choix :
     * - Suppression du cookie permettant la connexion automatique
     * - Déconnexion de l'utilisateur en cours (Affichage fenêtre de connection)
     *************************************************************************/
    const validLogout = useCallback(()=>{
        removeCookie('grc-remember')
        setLogged(false)
    },[removeCookie])

    return(
    <AuthProvider autoLogin={autoLogin}>
    {
    !logged ? 
        <LogIn setCookie={setCookie} setLogged={setLogged} /> :
        
            <BrowserRouter>
                <AppNav logout={validLogout} />
                <SidebardTop />
                <SidebardEnd />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/provider" element={<Provider />} />
                </Routes>
                <AppFooter />                  
            </BrowserRouter>
    }
    </AuthProvider>
    )
}
export default App;