import React, { useCallback } from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { useRef } from "react";

const styles = {
    height: '40px',
    minHeight: '40px',
    maxHeight: '40px',
    padding: '0px 10px',
    margin: '0px',
    cursor:"pointer"
}

export default React.memo(
    function HomeRow({value, onClick}){
        const ref = useRef()
        const handleClick = useCallback((e)=>{
            onClick(e,ref.current)
        },[onClick])
        
        return(
        <li ref={ref} id={value.id} className="container-fluid listRow py-1 " style={styles} onClick={handleClick}>
            <div className="row">
                <div className="col-auto">
                    <button className={"btn btn-sm "+(value.regime>0?"btn-secondary":"btn-light")}>
                        <span className={`badge  rounded-pill bg-${value.confederation}`} >{value.adhesion}</span>
                    </button>
                </div>
                <div className="col text-truncate">{value.boss}</div>
                <div className="col text-truncate">{value.name}</div>
                <div className="col text-truncate">{value.address}</div>
                <div className="col text-truncate">{value.zip}</div>
                <div className="col text-truncate">{value.city}</div>
                <div className="col-auto">
                    <span className={`badge bg-warning rounded-pill}`} >{value.free}</span>
                </div>
                <div id={"h"+value.id} className="col-auto">    
                    <button className={"btn btn-sm "+(!value.prospect ? "btn-danger":"btn-success")}><FontAwesomeIcon  icon={faUser} /></button>
                </div>
            </div>
        </li>
        )
    }
)