export default function BadgeTabWarning(props){

    return(
    <span>
        <span id={props.id} className="badge bg-warning">
            {props.value ?props.value : 0}
        </span>
        &nbsp;{props.label}
    </span>
    )
}