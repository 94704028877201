import React, { useState, createContext,useEffect } from "react";

const AuthContext = createContext();

const AuthProvider = (props) => {
    const [authState, setAuthState] = useState({
        user:null,
        identity:null,
        exchange:null,
        adresse:null,
        filters:null,
        ressources:null, // Permet de stoker les ressources sélectionnées avec le RessourceController 
    });  


    useEffect( ()=>{
        props.autoLogin(function(data){
            setAuthState(data)
            // setAuthState((state) => ({ ...state, ...data}))
        })
    },[props])  

    return (    
        <AuthContext.Provider value={[authState, setAuthState]}>      
            {props.children}
        </AuthContext.Provider>
    );
};
export { AuthContext, AuthProvider };