import React, {useEffect, useState} from "react"

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import HomeTabInfo from './homeTabInfo'
import HomeTabContact from "./HomeTabContact";
import BadgeTabWarning from "../../../components/app/BadgeTabWarning";

export default function HomeTabs({company,...props}){
    const height = 300
    const [key, setKey] = useState('information');

    const [action, setAction] = useState({})
    const [rappel, setRappel] = useState({})
    const [prospection, setProspection] = useState({})
    const [adhesion, setAdhesion] = useState({})

    // A chaque fois qu'une entreprise est chargé activation de l'onglet information 
    useEffect(()=>{setKey("information")},[company])

    return (
    <Tabs
        id="tabs-home"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-1"
      >
        <Tab eventKey="information" title="Information" >
            <HomeTabInfo activeKey={key} company={company} height={height} /> 
        </Tab>
        <Tab eventKey="contact" title={<BadgeTabWarning id="homeTabBadgeContact" label="Contacts"  value={action.total} />} >
            <HomeTabContact activeKey={key} company={company} height={height} />
        </Tab>
        <Tab eventKey="rappel" title={<BadgeTabWarning id="homeTabBadgeRappels" label="Rappels"  value={rappel.total} />}></Tab>
        <Tab eventKey="action" title={<BadgeTabWarning id="homeTabBadgeAction" label="Actions"  value={action.total} />}></Tab>
        <Tab eventKey="prospection" title={<BadgeTabWarning id="homeTabBadgeProspection" label="Prospections"  value={prospection.total} />}></Tab>
        <Tab eventKey="adhesion" title={<BadgeTabWarning id="homeTabBadgeAdhesion" label="Adhesions"  value={adhesion.total} />}></Tab>
    </Tabs>
    )
}