import React, { useEffect, useState, useRef, useCallback  } from 'react';
import scrollStop from '../../utils/scrollStopListener'
import API from '../../utils/api';
import {isEmpty} from '../../utils/miscellaneous';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from "@fortawesome/free-solid-svg-icons"

/**
 * Important La hauteur de la ligne passée en paramètre doit être exact 
 * pour ne pas générer de décalage dans le calcul de l'index 
 * @param {*} scrollTop 
 * @param {*} height 
 * @param {*} breackup 
 * @returns 
 */
function index(scrollTop, height = 40, breackup = 10){
    return scrollTop%height > breackup ? Math.ceil(scrollTop/height) : Math.floor(scrollTop/height)
}

export default  function ProviderList({setCompany, companies, setCompanies }){
    
    const [url, setUrl] = useState();
    const [page, setPage] = useState(-1);
    const loader = useRef(null);    
    const container = useRef(null);    

    /**
     * Permet de recupérer l'url suivante aprés chaque chargement des entreprises 
     */
    useEffect(()=>{setUrl(companies.next_page_url)},[companies])    

    /**
     * Charges les entreprises a chaque fois que l'utilisateur atteint la fin de la page
     * Un observer placer à la fin de la liste lance la demande de chargement
     */
    useEffect(()=>{if(page>0) {API.get(url).then(r=>{mergeRecords(r.data)})}},[page])    


    /**
     * Concatenation des entreprises déjà présente dans la liste  
     * et des nouvelles qui viennent d'être chargée
     * @param {*} response 
     */
    function mergeRecords (response){
        if(!isEmpty(response)){
            if(!isEmpty(companies)) {response.data = companies.data.concat(response.data)}
            setCompanies(response)
        }
    }
    
    /**
     * Récupéation des information concernant l'entreprise  
     * @param {Integer}  id 
     */
    function loadRecord (id){
        API.get(`companies/${id}`).then(r=>{setCompany(r.data)})               
    }

    /**
     * A la création du composant 
     * - Un IntersectionObserver est créé et attaché à la ligne "Chargement en cours"
     * - J'ecoute lorsque l'utilisateur arrete de scroller 
     * afin de selectionner la premieres ligne à l'écran 
     */
    useEffect(() => {

        var options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.25 //Lorsqhe 25% de la ligne est affichée lancement du callBack
        };

        /**
         * Création d'un observer sur la dernière ligne qui lance le callback
         * ici, nous gérons ce qui se passe lorsque l'utilisateur fait défiler 
         * jusqu'à Load More div dans ce cas, nous mettons simplement à jour la variable de page
         * qui lancera le chargement de nouvelles entreprise 
         * @param {Object} entities 
         */
        const handleObserver = (entities) => {
            if(entities[0].isIntersecting) {setPage((page) => page + 1)}
        }
        const observer = new IntersectionObserver(handleObserver, options);

        /**
         * Au premier Chargement recherche les entreprises
         * Lancement de l'observation
         */
        if (loader.current) {
            let row
            API.get(`providers?paginate=true&page=1`)
               .then(response => {mergeRecords(response.data)})
               .then(()=>{row = container.current.querySelectorAll(".listRow")[0]})
               .then(()=>{loadRecord(row.id)})
               .then(()=>{row.classList.add("bg-selected")})

            observer.observe(loader.current)
        }

        /**
         * Lorsque l'utilisateur arrête de scroller
         * - Selection de la ligne en fonction de la position du scroll
         * - chargement de l'entreprise par son identifiant   
         */
        scrollStop(()=>{
            const row = switchSelected()
            loadRecord(row.id)
        }, container);  

    }, []);    
    
    /**
     * - Selection de la position du scroll et calcul de l'index correspondant
     * - Déselection de la ligne selectionner
     * - Ajout de la nouvelle selection 
     * @param {*} container 
     * @returns 
     */
    const switchSelected = ()=>{
        const scrollContainer = container.current
        const row = container.current.querySelectorAll(".listRow")[index(scrollContainer.scrollTop)]
        
        const selected = container.current.querySelector(".bg-selected")
        if(selected) selected.classList.remove("bg-selected")
        row.classList.add("bg-selected")
        return row
    }

    /**
     * Lorsque l'utilisateur click sur une ligne 
     * Selection de la ligne courrante
     * Chargement des informations concernant l'entreprise
     */
    const handleClick = useCallback((e, row)=>{
        const selected = container.current.querySelector('.bg-selected')
        if(selected) selected.classList.remove("bg-selected")    
        row.classList.add("bg-selected") 
        loadRecord (row.id)
    },[])

    return (
    <ul ref={container} className="list-group list-group-flush vh-100" style={{overflowY:"scroll",overflowX:"hidden"}}>
        {
            (isEmpty(companies)) ? "" :
            companies.data.map((company, index) => {
                return(<HomeRow key={index} value={company} onClick={handleClick} />)
            }) 
        }        
        <li className="loading text-center" ref={loader}>Chargement en cours...</li>
    </ul>
    )
}


const styles = {
    height: '40px',
    minHeight: '40px',
    maxHeight: '40px',
    padding: '0px 10px',
    margin: '0px',
    cursor:"pointer"
}

//  React.memo(
    function HomeRow({value, onClick}){
        const ref = useRef()
        const handleClick = useCallback((e)=>{
            onClick(e,ref.current)
        },[onClick])
        
        return(
        <li ref={ref} id={value.id} className="container-fluid listRow py-1 " style={styles} onClick={handleClick}>
            <div className="row">
                <div className="col text-truncate">{value.name}</div>
                <div className="col text-truncate">{value.adresse.street}</div>
                <div className="col-auto text-truncate">{value.adresse.zip}</div>
                <div className="col text-truncate">{value.adresse.city}</div>
                <div className="col text-truncate">{value.exchange.phone}</div>
                <div className="col text-truncate">{value.exchange.mobile}</div>
                <div className="col text-truncate">{value.exchange.email}</div>
            </div>
        </li>
        )
    }
// )