import { useCallback, useEffect, useRef, useContext } from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotate, faPrint } from "@fortawesome/free-solid-svg-icons"

import { AuthContext } from '../app/AuthContext'

import api from "../../utils/api"
  
/* HomeListFooter
 * @param {*} param0 
 * @returns 
 *****************************************************************************/
export default function HomeListFooter({companies,setCompanies,...props}) {
    const [authState,setAuthState] = useContext(AuthContext);     

    const refType = useRef();
    const refValue = useRef();

    const handleSubmit = useCallback((e)=>{
        e.preventDefault()
        const config = {
            params :{
                paginate: true, 
                [refType.current.value] : refValue.current.value
            }
        }

        api.get(`companies`,config)
        .then(r=>{setCompanies(r.data)})
        .then(()=>{setAuthState({...authState,filters:config.params})})
        // eslint-disable-next-line react-hooks/exhaustive-deps                          
    },[setCompanies])

    const handleRefresh = useCallback((e)=>{
        refType.current.value  = "boss"       
        refValue.current.value = "" 
        
        api.get("companies?paginate=true")
        .then(r=>{setCompanies(r.data)})
        .then(()=>{setAuthState({...authState,filters:{}})})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[setCompanies])

    useEffect(()=>{refType.current.value  = "boss"},[])

    return(
    <div className="card-footer p-1">
        <div className="row">
            <div className="col-10">
            <form className="row g-1" onSubmit={handleSubmit}>
                <div className="col-auto">
                    <select ref={refType} id="footerSearchSelect" className="form-select form-select-sm">
                        <option value="code">Code de l'entreprise</option>
                        <option value="boss">Chef d'entreprise</option>
                        <option value="name">Nom de l'entreprise</option>
                        <option value="zip">Code postal</option>
                        <option value="city">Commune</option>
                        <option value="email">Email</option>
                        <option value="phone">Téléphone</option>
                        <option value="mobile">Mobile</option>
                        <option value="fax">Télécopie</option>
                        <option value="forme">Forme juridique (code)</option>
                    </select>
                </div>
                <div className="col-auto">
                    <input ref={refValue} id="footerSearchInput" type="text" className="form-control form-control-sm" placeholder="Zone de recherche"/>
                </div>
                
                <div className="col-auto">
                    <button type="button" className="btn btn-sm btn-primary" title="Rafraichir la zone de recherche" onClick={handleRefresh}>
                        <FontAwesomeIcon icon={faRotate} />
                    </button>
                </div>

                <div className="col-auto">
                    <button type="submit" className="btn btn-sm btn-primary" title="Lancer la recherche" >ok</button>
                </div>

                <div className="col-auto">
                    <label >Affichage : {companies.total} entreprise(s)</label>
                </div>
            </form>
            </div>
            <div className="col-2 text-end">
                <a href="/#" className="btn btn-primary btn-sm" title="Impression de la fiche entreprise"><FontAwesomeIcon icon={faPrint} /></a></div>
        </div>
    </div>  
    )
}