import React, { useState, useContext, useEffect} from 'react';
import { AuthContext } from '../AuthContext'

import api from '../../../utils/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser, } from "@fortawesome/free-solid-svg-icons"

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import Box from '@mui/material/Box';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import SelectBasic from '../../../components/html/select/single/SelectBasic';
import TextBasic from '../../../components/html/input/TextBasic';
import DateBasic from '../../../components/html/input/DateBasic';

/* BOXPROFILE
 * Affichage de la fenêtre de personnalisation du profile de l'utilisateur
 * @returns 
 ******************************************************************************/
export default function BoxProfile(){
    const [show, setShow] = useState(false) 

    return(
    <li>
        <a href="/#" className="dropdown-item" onClick={setShow} ><FontAwesomeIcon icon={faCircleUser} /> Mes informations</a>
        {show && <BoxModal show={show} setShow={setShow}/>}
    </li>
    )
}

/*BOXMODAL
 * Affichage des information modifiable dans une fenêtre modale 
 * @param {*} param0 
 * @returns 
 ******************************************************************************/
function BoxModal({show,setShow}){
    const [loading, setLoading] = useState(false) 
    const [authState, setAuthState] = useContext(AuthContext);
    // Création d'une copie de l'utilisateur authentifié car si l'utilisateur
    // annule les modifications, les valeurs stocker dans authState n'auront pas
    // été modifié
    const [values, setValues] =  useState(authState);

    const handleCancel = () => {setShow(false)}
    
    const handleValid = () =>{
        if(values.identity.genre_id && values.identity.firstname && values.identity.lastname){
            setLoading(true)
            setAuthState(values)
            api.put(`user/card/${values.identity.id}`,values)
               .catch((e)=>{console.log(e)})
               .finally(()=>{setShow(false)})
        }
    }
    
    return(
        <Modal show={show}  onHide={handleCancel} backdrop="static" >
        <Modal.Header closeButton>
            <Modal.Title>Mes informations</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <TabsProfile values={values} setValues={setValues} height={224} />
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel}>Annuler</Button>
            <Button variant="primary" disabled={loading} onClick={handleValid}>Valider</Button>
        </Modal.Footer>
    </Modal>         
    )    
}

/* TabsProfile
 * @param {*} props 
 * @returns 
 *****************************************************************************/
function TabsProfile({values, setValues,...props}) {
    const [key, setKey] = useState('identity');

    const [identity, setIdentity] = useState(values.identity) 
    const [adresse, setAdresse] = useState(values.adresse) 
    const [exchange, setExchange] = useState(values.exchange) 

    useEffect(()=>{
        setValues((values)=>( {...values,
            identity :  identity,
            adresse :  adresse,
            exchange :  exchange,
        }))
    },[setValues, identity, adresse, exchange])

    return (
    <Tabs
        id="tabs-profile"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="identity" title="Identité">
            <Identity identity={identity} setIdentity={setIdentity} height={props.height}/>
        </Tab>
        <Tab eventKey="adresse" title="Adresse">
            <Adresse adresse={adresse} setAdresse={setAdresse} height={props.height}/>
        </Tab>
        <Tab eventKey="exchange" title="Communication">
            <Exchange exchange={exchange} setExchange={setExchange} height={props.height}/>
        </Tab>
    </Tabs>
    );
  }

/* Identity
 * @param {*} param0 
 * @param {*} props 
 * @returns 
 *****************************************************************************/
function Identity({identity,setIdentity},props){
    const sx = {height:props.height,'& .MuiTextField-root': {mb:1, mt:1}}
    const handleChange = function(e){
        const {id, value} = e.target
        setIdentity({...identity,[id]: value})
    } 
    const options = [
        {value:1, label:"Monsieur"},
        {value:2, label:"Madame"}
    ]

    useEffect(()=>{},[])

    return(
    <Box component="form" sx={sx} noValidate autoComplete="off">      
        <SelectBasic id="genre_id" label="Civilité" required value={identity.genre_id} onChange={handleChange} options={options} />     
        <TextBasic id="firstname" label="Prénom" required value={identity.firstname} onChange={handleChange} /> 
        <TextBasic id="lastname" label="Nom" required value={identity.lastname} onChange={handleChange} /> 
        <DateBasic id="birthday" label="Date de naissance" required value={identity.birthday} onChange={handleChange} />
    </Box>        
    )
}

/* Adresse
 * @param {*} param0 
 * @param {*} props 
 * @returns 
 *****************************************************************************/
function Adresse({adresse,setAdresse,...props}){
    const sx = {height:props.height,'& .MuiTextField-root': {mb:1, mt:1}}
    
    const handleChange = (e) =>{
        const {id, value} = e.target
        setAdresse({...adresse,[id]: value})
    } 
    
    return(
    <Box component="form" sx={sx} noValidate autoComplete="off">
        <TextBasic id="street" label="Adresse" required value={adresse.street} onChange={handleChange} /> 
        <TextBasic id="extra" label="Complément" value={adresse.extra} onChange={handleChange} /> 
        <TextBasic id="zip" label="Code postal" required value={adresse.zip} onChange={handleChange} /> 
        <TextBasic id="city" label="Commune" required value={adresse.city} onChange={handleChange} /> 
    </Box>
    )
}

/* Exchange
 * @param {*} param0 
 * @param {*} props 
 * @returns 
 *****************************************************************************/
function Exchange({exchange,setExchange,...props}){
    const sx = {height:props.height,'& .MuiTextField-root': {mb:1, mt:1}}
    
    const handleChange = (e) =>{
        const {id, value} = e.target
        setExchange({...exchange,[id]: value})
    }
    
    return(
    <Box component="form" sx={sx} noValidate autoComplete="off">
        <TextBasic id="email" label="Courriel" required value={exchange.email} onChange={handleChange} />
        <TextBasic id="phone" label="Téléphone" required value={exchange.phone} onChange={handleChange} />
        <TextBasic id="mobile" label="Mobile" value={exchange.mobile} onChange={handleChange} />
    </Box>
    )
}