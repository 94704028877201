import SelectBasic from "../html/select/single/SelectBasic"

export default function SelectCivilite(props){

    // Permet de modifier le tableau de civilité pour qu'il soit compatible au format des options 
    const options = props.genres.map(({id, civilite})=>({label:civilite, value:id}))     

    return(
        <SelectBasic 
            id={props.id} 
            error={props.value < 1 ? true : false} 
            required={props.required} 
            label={props.label} 
            value={props.value} 
            onChange={props.onChange} 
            options={options} 
        />
    )
}
