import  {useEffect, useState, useContext} from 'react';
import { AuthContext } from '../app/AuthContext'

import  './homeStyles.css'
import AppMenu from "../app/appMenu";
import HomeTabs from "./homeTabs/homeTabs";

import HomeListHeader from "./HomeListHeader";
import HomeList from "./HomeList";
import HomeListFooter from "./HomeListFooter";

import {useCurrentHeight} from "../../utils/resizeListener"

export default function Home(){
    
    const styles = {...useCurrentHeight(430),...{marginBottom:25}}
    const [authState,setAuthState] = useContext(AuthContext);
    const [company, setCompany] = useState({})
    const [companies, setCompanies] = useState({})

    useEffect(()=>{
        setAuthState({...authState,filters:{}})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
    <div className="container-fluid">
        <div className="row">
            <AppMenu />
            <main className="px-md-1">
                <HomeTabs company={company?company:null} setCompany={setCompany}/>
                <div className="card fixed-bottom" style={styles}>
                    <HomeListHeader  companies={companies} setCompanies={setCompanies} />
                    <HomeList company={company}  setCompany={setCompany} companies={companies} setCompanies={setCompanies}/>
                    <HomeListFooter companies={companies} setCompanies={setCompanies} />
                </div> 
            </main>
        </div>
    </div>
    )


}