import React, { useEffect, useState, useRef, useCallback  } from 'react';
import api from '../../../utils/api';
import { isEmpty } from '../../../utils/miscellaneous';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import SplitButton from 'react-bootstrap/SplitButton'
import Dropdown from 'react-bootstrap/Dropdown'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrash, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons"

import Box from '@mui/material/Box';

import TextareaBasic from  '../../../components/html/textarea/TextareaBasic';


/* SettingsRelations
 * Affiche la liste des confédérations et permet leurs gestions
 * Affiche le nombre total des confédérations
 * Recherche des confédérations par leur code  
 **************************************************************************/
export default function SettingsRelations({active}){
    const container = useRef()
    const search = useRef()
    // Recupération des donnees du serveur
    const [records, setRecords] = useState([])
    const [dropdown, setDropdown] = useState({value:"value",label:"Libellé"})    

    // Option par défaut de la fenêtre modal
    const [optBox, setOptBox] = useState({
        id:0,
        onCancel:()=>{setOptBox({...optBox,show:false})},
        onValid:()=>{
            fnLoad()
            setOptBox({...optBox,show:false})
        }
    })

    // Option par défaut de la fenêtre confirmation de supression
    const [optConfirm, setOptConfirm] = useState({
        id:0,
        onCancel:()=>{setOptConfirm({...optConfirm,show:false})},
        onValid:()=>{
            fnLoad()
            setOptConfirm({...optConfirm,show:false})
        }
    })

    // Lorsque le tab est actif chargement/ rechargement de la liste
    // Lorsque une recherche est lancé chargement de la liste
    // La ligne de commentaire permet la suppression du WARNING levé
    // lors de la compilation    
    useEffect(()=>{
        if(active === `#${container.current.id}`){fnLoad()}
        setTimeout(()=>{search.current.focus()},250) 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[active,search])

    const fnLoad = ()=>{
        api.get(`/relations/?${dropdown.value}=${search.current.value}`)
        .then((resp)=>{setRecords(resp.data)})
    }

    const fnInsert = (e,row)=>{
        setOptBox({...optBox,
            action:'insert',
            id:0,
            show:true,
        })
    }    

    const fnUpdate = (e,row)=>{
        setOptBox({...optBox,
            action:'update',
            id:row.id,
            show:true,
        })
    }

    const fnDelete = (e,row)=>{
        setOptConfirm({...optConfirm,
            action:'delete',
            id:row.id,
            show:true,
        })
    }    

    const fnSearch = (e)=>{
        e.preventDefault()
        fnLoad()
    }

    const handleSelect = (v,e)=>{
        setDropdown({value:v,label:e.target.innerText})
    }


    return(
    <div ref={container} className="tab-pane fade  h-100" id="settings-relations" role="tabpanel">     
        <div className="card h-100">
            <div className="card-header fw-bold">
            <div className="row">
                    <div className="col-10">
                        Liste des types de relation
                    </div>
                    <div className="col-2 text-end">
                        <span className="badge bg-warning">{records.length}</span> 
                    </div>
                </div>        
            </div>
            <div className="card-body scrollY p-1" style={{maxHeight:210}}>
                <ul className="list-group list-group-flush">
                {(isEmpty(records)) ? "" : records.map((record, index) => (<Row key={index} value={record} onUpdate={fnUpdate} onDelete={fnDelete}/>))}
                </ul>
            </div>
            <div className="card-footer">
                <div className="row">
                <div className="col-6">
                        <form className="row g-1" onSubmit={fnSearch}>
                        <InputGroup className="">
                            <SplitButton
                                variant="outline-secondary "
                                title={dropdown.label}
                                size="sm"
                                onSelect={handleSelect}
                            >
                                <Dropdown.Item  eventKey="value">Libellé</Dropdown.Item>
                            </SplitButton>
                            <FormControl  ref={search} type='search' size="sm" placeholder='Zone de recherche'/>
                        </InputGroup>
                        </form>
                    </div>
                    <div className="col-6 text-end">
                        <button className='btn btn-sm btn-primary' onClick={fnInsert} title='Ajouter un enregistrement'><FontAwesomeIcon icon={faPlus} /></button> 
                    </div>
                </div>
            </div>
        </div>
        {optBox.show && <BoxModal optBox={optBox} setOptBox={setOptBox}/>}
        {optConfirm.show && <BoxConfirm optConfirm={optConfirm}/>}
    </div>
    )
}

/* ROW
 * Lignes du tableau
 *****************************************************************************/
function Row({value, onUpdate, onDelete}){
    const row = useRef()

    const handleUpdate = useCallback((e)=>{
        onUpdate(e,row.current)
    },[onUpdate])

    const handleDelete = useCallback((e)=>{
        onDelete(e,row.current)
    },[onDelete])
    
    return(
    <li ref={row} id={value.id} className="list-group-item row-striped py-1 px-1">
        <div className="row">
            <div className="col text-truncate p-default">{value.value}</div>
            <div className="col-auto pe-1"><button className='btn btn-sm btn-primary' onClick={handleUpdate}><FontAwesomeIcon icon={faEdit} /></button></div>
            <div className="col-auto ps-1"><button className='btn btn-sm btn-primary' onClick={handleDelete}><FontAwesomeIcon icon={faTrash} /></button></div>
        </div>    
    </li>   
    )
}    

/* BoxModal
 * Fenêtre de la relation
 *****************************************************************************/

function BoxModal({optBox,...props}){
    const [errors, setErrors] = useState({})         
    const [values, setValues] = useState(null)         
    const [loading, setLoading] = useState(false) 

    
    const handleCancel = () => {
        optBox.onCancel()
    }
    
    /**
     * Test l'ensemble des selecteurs du formulaire pour autoriser la validation
     * @param {*} id 
     * @returns 
     */
    const isValid = (id)=>{
        let result = true
        const form = document.querySelector(id)    
        const elts = form.querySelectorAll("[required]")
              elts.forEach((elt,i)=>{
                if(elt.name !== undefined && !elt.validity.valid) result = false 
              })
        return result
    } 

    const fnUpdate = ()=>{
        api.put(`relations/${optBox.id}`,values)
        .then((resp)=>{optBox.onValid()})
        .catch((e)=>{})
    }

    const fnCreate = ()=>{
        api.post(`relations`,values)
        .then((resp)=>{optBox.onValid()})
        .catch((e)=>{})
    }


    const handleValid = () => {
        if(!isValid('#SettingsRelations')) return
        setLoading(true)
        if(optBox.id > 0)fnUpdate()
        else fnCreate()               
    }

    /* Chargement des données si aucune donnée n'est retourné dans le cas d'une
     * creation creation d'un objet values vide pour afficher la fenêtre de création
     *************************************************************************/   
    useEffect(()=>{
        api.get(`relations/${optBox.id}`)
        .then((resp)=>{setValues(resp.data || {})}) 
    },[optBox])

    return(
    <Modal id="SettingsRelations" show={optBox.show}  onHide={handleCancel} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>Type de relation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {values && <BodyRelation setErrors={setErrors} errors={errors} values={values} setValues={setValues} />}
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel}>Annuler</Button>
            <Button variant="primary" disabled={loading}  onClick={handleValid}>valider</Button>
        </Modal.Footer>
    </Modal> 
    )
}

/* BodyRelation
 * @param {*} param0 
 * @param {*} props 
 * @returns 
 *****************************************************************************/
function BodyRelation({errors, values, ...props}){
    const sx = {height:props.height,'& .MuiTextField-root': {mb:1, mt:1}}

    const handleChange = function(e){
        const {id, value} = e.target
        props.setValues({...values,[id]: value})
    } 

    useEffect(()=>{},[])

    return(
    <Box component="form" sx={sx} noValidate autoComplete="off">  
        <TextareaBasic id="value" label="Description" value={values.value} rows={4} onChange={handleChange} required/>  
    </Box>        
    )
}


/* BoxConfirm
 * Fenêtre de confirmation en cas de suppression
 * @param {*}  optConfirm
 * @returns 
 *****************************************************************************/
function BoxConfirm({optConfirm}){
    const [loading, setLoading] = useState(false)     
    const handleCancel = () => {
        optConfirm.onCancel()
    }
    
    const handleValid = () => {
        setLoading(true)
        api.delete(`/relations/${optConfirm.id}`)
        .then((resp)=>{optConfirm.onValid()})
    }    

    return(
        <Modal show={optConfirm.show}  onHide={handleCancel} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>Attention</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            Confirmez vous la suppression ?
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel}>Annuler</Button>
            <Button variant="primary" disabled={loading}  onClick={handleValid}>valider</Button>
        </Modal.Footer>
    </Modal> 
    )    
}