import Axios from 'axios'

// Pour modifier les parametres fixer par défaut 
// il est possible par exemple dans le script appellant
// API.defaults.baseURL = http://localhost:3001/

export default Axios.create({
//  baseURL: `http://localhost:8080/`,
  baseURL: window.BASE_API,
  responseType: 'json',
  withCredentials: true
});


