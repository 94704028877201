import { useEffect,useState,useContext } from "react"
import api from '../../utils/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faPen,faPrint,faFileExcel, faSpinner } from "@fortawesome/free-solid-svg-icons"

import { AuthContext } from '../app/AuthContext'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import FilterSingle from "../../components/html/filters/FilterSingle";
import FilterPicker from "../../components/html/filters/FilterPicker";
import FilterSwitchSlider from "../../components/html/filters/FilterSwitchSlider";

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'


/* HomeListHeader
 * @param {*} param0 
 * @returns 
 *****************************************************************************/
export default function HomeListHeader({companies, setCompanies, ...props}){
    const [authState,setAuthState] = useContext(AuthContext); 

    // Option par défaut de la fenêtre de téléchargement du fichier
    const [optExport, setOptExport] = useState({
        onCancel:()=>{setOptExport({...optExport,show:false})},
    }) 

    const [optFilter, setOptFilter] = useState({
        onValid:(filters)=>{
            const config = {
                params :{...filters,
                    user : authState.user.id,
                    paginate: true, 
                }
            }
    
            api.get(`companies`,config)
            .then(r=>{setCompanies(r.data)})
            setOptFilter({...optFilter,show:false})
        },
        onCancel:()=>{setOptFilter({...optFilter,show:false})},
        onReset:()=>{setOptFilter({...optFilter,show:true})},
    }) 

    const handleFilterOn = (e)=>{
        e.preventDefault()
        setOptFilter({...optFilter,show:true})
    }
    
    const handleFilterOff = (e)=>{
        e.preventDefault()
        setAuthState({...authState,filters:{}})
        document.querySelector("#footerSearchSelect").value = "boss"
        document.querySelector("#footerSearchInput").value = ""
        api.get(`companies`,{params :{paginate:true}})
        .then(r=>{setCompanies(r.data)})        
    }

    const handleExport = (e)=>{
        e.preventDefault()
        const params = {
            params : {
                ...authState.filters,
                user : authState.user.id,
                paginate : false
            }
        }

        setOptExport({...optExport,show:true});
        api.get(`/export/companies`,params)
        .then((resp)=>{setOptExport({...optExport,url:resp.data.url,show:true})})
    }

    const handleCreate = (e)=>{console.log(authState.filters)}
    const handleUpdate = (e)=>{}
    const handleDelete = (e)=>{}
    const handlePrint  = (e)=>{}

    useEffect(()=>{},[])

    function isEmpty(obj) {
        if(!obj) return true
        return Object.keys(obj).length === 0
    }

    return (
    <nav className="nav d-flex">
        <a href="/#" className="ps-1 flex-grow-1 fs-4 text-decoration-none text-reset d-none d-sm-block" disabled >Liste des entreprises</a> 
        <a href="/#" onClick={handleFilterOn} className="nav-link my-1 px-2" data-bs-toggle="tab" data-bs-target="#filterOn" role="tab" title="Filtrer les fiches"><FilterAltIcon /></a>
        {isEmpty(authState.filters) ? "" : <a href="/#" onClick={handleFilterOff} className="nav-link my-1 px-2" data-bs-toggle="tab" data-bs-target="#filterOff" role="tab" title="Chercher des fiches"><FilterAltOffIcon color="warning"/></a>}
        <a href="/#" onClick={handleCreate} className="nav-link my-1 " data-bs-toggle="tab" data-bs-target="#create" role="tab" title="Créer une fiche"><FontAwesomeIcon icon={faPlus} /></a>
        <a href="/#" onClick={handleUpdate} className="nav-link my-1" data-bs-toggle="tab" data-bs-target="#update" role="tab"  title="Editer la fiche"><FontAwesomeIcon icon={faPen} /></a>
        <a href="/#" onClick={handleDelete} className="nav-link my-1" data-bs-toggle="tab" data-bs-target="#delete" role="tab"  title="Supprimer la fiche"><FontAwesomeIcon icon={faTrash} /></a>
        <a href="/#" onClick={handlePrint} className="nav-link my-1" data-bs-toggle="tab" data-bs-target="#print" role="tab" title="Imprimer la fiche"><FontAwesomeIcon icon={faPrint} /></a>
        <a href="/#" onClick={handleExport} className="nav-link my-1" data-bs-toggle="tab" data-bs-target="#export" role="tab" title="Exporter Au format CSV" ><FontAwesomeIcon icon={faFileExcel} /></a>
        {optExport.show && <BoxExport optExport={optExport}/>}
        {optFilter.show && <BoxFilter optFilter={optFilter}/>}
    </nav>
    )
}

/* BoxExport
 * Fenêtre de confirmation en cas de suppression
 * @param {*}  optExport
 * @returns 
 *****************************************************************************/
function BoxExport({optExport}){
    const handleCancel = () => {optExport.onCancel()}

    return(
        <Modal show={optExport.show}  onHide={handleCancel} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>Export au format CSV</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p className="text-center fs-4 text-muted">
                { !optExport.url ? 
                <FontAwesomeIcon icon={faSpinner} spin/> : 
                <><FontAwesomeIcon icon={faFileExcel} />  <br/> <a className="text-reset" href={optExport.url} download onClick={handleCancel}>Télécharger le fichier</a></> 
                }
            </p>
        </Modal.Body>
    </Modal> 
    )    
}

function BoxFilter({optFilter}){
    const [authState,setAuthState] = useContext(AuthContext);
    const [loading, setLoading] = useState(false) 
    const [reset, setReset] = useState(false)
    const [confederation, setConfederation] = useState([])
    const [key, setKey] = useState('information');
    let filters = {}

    const handleReset = () => {
        setReset(true)
        setTimeout(()=>{setReset(false)},100)
    }

    const handleCancel = () => {
        optFilter.onCancel()
    }
    const handleValid = () => {
        // console.log("filters",filters)
        setLoading(true)
        setAuthState({...authState,filters:filters})
        optFilter.onValid(filters)
    }

    useEffect(()=>{
        if(!authState.ressources){
            api.get(`ressources`)
            .then((r)=>{setAuthState({...authState, ressources:r.data})})
        }
    },[])

    const prospects = [
        {value:1, label:"Oui"},
        {value:0, label:"Non"},
    ]
    const services = [
        {value:1, label:"Oui"},
        {value:0, label:"Non"},
    ]

    const handleChange = (e,item)=>{
        //if(item.id === "confederations") setConfederation(item.value)
        //console.log("handleChange",item.id,item.value)
        if(item.value.toString().length > 0) {
            filters[item.id] = {
                value:item.value,
                compare:item.compare,
            }
        } else {delete filters[item.id]}
    }


    // const filterSyndicat = (syndicat)=> {
    //     return  ['CNAMS','CAPEB'].some(e=> e === syndicat.code)  
    // }

    const home = ()=>{
        return(
        <div className="row row-cols-3">
            <div className="col">
                <FilterPicker 
                    id="adhesion" 
                    label="Adhésion" 
                    onChange={handleChange}  
                    reset={reset} 
                    filter={(authState.filters && authState.filters.adhesion) || ""} 
                    options={authState.ressources && authState.ressources.adhesions}
                /> 
            </div>
            <div className="col">
                <FilterPicker 
                    id="confederation" 
                    label="Confédération" 
                    onChange={handleChange}  
                    reset={reset} 
                    filter={(authState.filters && authState.filters.confederation) ||{}} 
                    options={authState.ressources && authState.ressources.confederations}
                />
            </div>
            <div className="col">
                <FilterPicker 
                    id="syndicat" 
                    label="Syndicat" 
                    onChange={handleChange}  
                    reset={reset} 
                    filter={(authState.filters && authState.filters.syndicat) ||{}} 
                    options={authState.ressources && authState.ressources.syndicats }
                    groupe = "code"
                />
            </div>
            <div className="col">
                <FilterPicker 
                    id="withforme" 
                    label="Forme juridique" 
                    onChange={handleChange}  
                    reset={reset} 
                    filter={(authState.filters && authState.filters.withforme) ||{}} 
                    options={authState.ressources && authState.ressources.formes }
                />
            </div>
            <div className="col">
                <FilterPicker 
                    id="activity" 
                    label="Activitité(s)" 
                    onChange={handleChange}  
                    reset={reset} 
                    filter={(authState.filters && authState.filters.activity) ||{}} 
                    options={authState.ressources && authState.ressources.activities }
                    groupe = "syndicat"
                />
            </div>
            <div className="col">
                <FilterSwitchSlider 
                    id="prospect" 
                    label="Prospect" 
                    onChange={handleChange}  
                    reset={reset} 
                    filter={(authState.filters && authState.filters.prospect) || ""} 
                />
            </div>
            <div className="col">
                <FilterSwitchSlider  
                    id="withservice" 
                    label="Service(s) gratuit" 
                    onChange={handleChange} 
                    reset={reset} 
                    filter={(authState.filters && authState.filters.withservice) || ""}
                />
            </div>
            <div className="col">
                <FilterSwitchSlider 
                    id="withemail" 
                    label="Courriel" 
                    onChange={handleChange} 
                    reset={reset} 
                    filter={(authState.filters && authState.filters.withemail) || ""}
                />
            </div>
            <div className="col">
                <FilterSwitchSlider  
                    id="withphone" 
                    label="Téléphone" 
                    onChange={handleChange} 
                    reset={reset} 
                    filter={(authState.filters && authState.filters.withphone) || ""}
                />
            </div>
            <div className="col">
                <FilterSwitchSlider 
                    id="withmobile" 
                    label="Mobile" 
                    onChange={handleChange} 
                    reset={reset} 
                    filter={(authState.filters && authState.filters.withmobile) || ""}
                />
            </div>
        </div>
        )
    }  
    
    const profile = ()=>{
        return(
        <div className="row row-cols-3">
            <div className="col">
            </div>    
        </div>
        )
    }
    
    const contact = ()=>{
        return(
        <div className="row row-cols-3">
            <div className="col">
            </div>    
        </div>
        )
    }

    return(
    <Modal show={optFilter.show}  onHide={handleCancel} backdrop="static" size="xl">
        <Modal.Header closeButton>
            <Modal.Title>Filtrer les entreprises</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Home</button>
                <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</button>
                <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</button>
            </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" style={{height:250}} >{home()}</div>
            <div className="tab-pane fade" id="nav-profile" role="tabpanel" style={{height:250}}>{profile()}</div>
            <div className="tab-pane fade" id="nav-contact" role="tabpanel" style={{height:250}} >{contact()}</div>
        </div>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={handleReset}>initialiser</Button>
            <Button variant="secondary" onClick={handleCancel}>Annuler</Button>
            <Button variant="primary" disabled={loading}  onClick={handleValid}>Rechercher</Button>            
        </Modal.Footer>
    </Modal> 
    )
    
      


}